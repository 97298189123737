import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Banner,
  LegacyStack,
  DropZone,
  Thumbnail,
  Text,
  FormLayout,
  List,
} from "@shopify/polaris";
import { NoteIcon } from "@shopify/polaris-icons";

import { useCreateVendorPaymentAttachmentMutation } from "../../services/api";

import { DirectUploadProvider } from "react-activestorage-provider";
import { isMobile } from "react-device-detect";

import FadeIn from "../FadeIn";
import AiLoader from "../AiLoader";

const AttachmentUpload = (props) => {
  const {
    isWithinCard,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop,
    onFileDialogClose,
    onUploadSuccess,
    openFileDialog,
    opportunityId,
    vendorPaymentId,
    children,
    finalInvoice,
  } = props;

  const [createVendorPaymentAttachment, { isLoading }] =
    useCreateVendorPaymentAttachmentMutation();

  const [file, setFile] = useState();

  const [rejectedFiles, setRejectedFiles] = useState([]);
  const hasError = rejectedFiles.length > 0;
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(false);

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles, handleUpload) => {
      onDrop && onDrop(_dropFiles, acceptedFiles, _rejectedFiles);

      setFileUploadSuccess(false);
      setFileUploadError(false);
      setFile((file) => acceptedFiles[0]);
      setRejectedFiles(_rejectedFiles);

      handleUpload(acceptedFiles);
    },
    [onDrop]
  );

  const validImageTypes = ["image/jpeg", "image/png"];

  const fileUpload = !file && <DropZone.FileUpload />;
  const uploadedFiles = file && (
    <LegacyStack vertical>
      <LegacyStack alignment="center">
        <Thumbnail
          size="small"
          alt={file.name}
          source={
            validImageTypes.includes(file.type)
              ? window.URL.createObjectURL(file)
              : NoteIcon
          }
        />
        <div>
          {file.name}  <Text variant="bodySm" as="p">{file.size} bytes</Text>
        </div>
      </LegacyStack>
    </LegacyStack>
  );

  const fileTypeErrorMessage = hasError && (
    <Banner title="The following files couldn’t be uploaded:" tone="critical">
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file.name}" is not supported. File type must be .pdf, .png, or .jpg.`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  const handleAttachment = (signedIds) => {
    // Remove set file to nil entirely
    // setFile();

    return createVendorPaymentAttachment({
      body: {
        invoice: signedIds[0],
        ...(vendorPaymentId && { id: vendorPaymentId }),
        ...(opportunityId && { opportunity_id: opportunityId }),
      },
      finalInvoice: finalInvoice,
    })
      .unwrap()
      .then((result) => {
        setFileUploadSuccess(true);
        onUploadSuccess && onUploadSuccess(result);
        return { status: "success" };
      })
      .catch((error) => {
        setFileUploadError(true);
        console.log(error);
      });
  };

  const token = window.localStorage.getItem("access-token");
  const client = window.localStorage.getItem("client");
  const uid = window.localStorage.getItem("uid");

  return (
    <DirectUploadProvider
      multiple={false}
      onSuccess={handleAttachment}
      headers={{ "access-token": token, client: client, uid: uid }}
      render={({ handleUpload, uploads, ready }) => {
        return <>
          {!isMobile && (
            <FadeIn fadeIn={isLoading}>
              <div
                style={{
                  height: "calc(100vh - 5.6rem)",
                  width: "99.9%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "-160px",
                  }}
                >
                  <div
                    style={{
                      height: "285px",
                      width: "285px",
                    }}
                  >
                    <AiLoader isMobile={false} isWithinCard={isWithinCard} />
                  </div>
                </div>
              </div>
            </FadeIn>
          )}

          {isMobile && isLoading && (
            <FadeIn fadeIn={isLoading}>
              <div
                style={{
                  height: "260px",
                  width: "260px",
                  ...(isMobile && {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    margin: "0 auto",
                  }),
                }}
              >
                <AiLoader isMobile={true} />
              </div>
            </FadeIn>
          )}

          {!isLoading && (
            <FormLayout>
              {/* {fileUploadSuccess && (
                <Banner status="success" title="Invoice uploaded">
                  We have received your document. If you would like to upload
                  an additional attachment, please do so now.
                </Banner>
              )} */}
              {fileUploadError && !hasError && (
                <Banner tone="critical" title="Upload failed">
                  We were unable to upload the document you provided due to a
                  system error. Please try again in a few minutes.
                </Banner>
              )}
              {fileTypeErrorMessage}
              <DropZone
                accept={["application/pdf", ...validImageTypes]}
                allowMultiple={false}
                dropOnPage
                errorOverlayText="File type must be .pdf, .png, or .jpg"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDragOver={onDragOver}
                onDrop={(_dropFiles, acceptedFiles, _rejectedFiles) =>
                  handleDropZoneDrop(
                    _dropFiles,
                    acceptedFiles,
                    _rejectedFiles,
                    handleUpload
                  )
                }
                onFileDialogClose={onFileDialogClose}
                openFileDialog={openFileDialog}
                variableHeight
              >
                {uploadedFiles}
                {fileUpload}
                {children}
              </DropZone>
            </FormLayout>
          )}
        </>;
      }}
    />
  );
};

AttachmentUpload.propTypes = {
  isWithinCard: PropTypes.bool,
  vendorPaymentId: PropTypes.string,
  onDragEnter: PropTypes.func,
  onDragLeave: PropTypes.func,
  onDragOver: PropTypes.func,
  onDrop: PropTypes.func,
  onFileDialogClose: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  openFileDialog: PropTypes.bool,
  opportunityId: PropTypes.string,
  vendorPaymentId: PropTypes.string,
  children: PropTypes.object,
};

export default AttachmentUpload;
