import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import {
  Badge,
  Filters,
  ResourceList,
  ResourceItem,
  Text,
  LegacyStack,
  BlockStack,
  InlineStack,
  Pagination,
  EmptyState,
  Icon,
  Truncate,
  ChoiceList,
  Card,
  SkeletonBodyText,
  SkeletonThumbnail,
} from "@shopify/polaris";

import { useDebounce } from "use-debounce";
import { NoteIcon } from "@shopify/polaris-icons";

import { useGetAdminVendorOpportunitiesQuery } from "../../../services/api";

import { formatCurrency } from "../../../utilities";
import emptySearchCompanies from "../../../assets/emptySearchCompanies.svg";

import { isDesktop } from "react-device-detect";

const AdminVendorOpportunityList = (props) => {
  const resourceName = {
    singular: "opportunity",
    plural: "opportunites",
  };

  const {
    stageFilters,
    format,
    onCreateOpportunity,
    onOpportunityClick,
    vendorId,
    pageLimit = 5,
  } = props;

  const [queryValue, setQueryValue] = useState(undefined);
  const [searchValue] = useDebounce(queryValue, 500);
  const [sortValue, setSortValue] = useState("UPDATED_AT_DESC");
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const handleQueryValueRemove = useCallback(
    () => setQueryValue(undefined),
    []
  );
  const handleQueryChange = (value) => setQueryValue(value);

  const [opportunityStage, setOpportunityStage] = useState([...stageFilters]);
  const handleOpportunityStageChange = useCallback((value) => {
    setOpportunityStage(value);
  }, []);

  const [limit, setLimit] = useState(pageLimit);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [disambiguatedFilters, setDisambiguatedFilters] = useState(null);

  const {
    data: opportunityData = {},
    isLoading: queryIsLoading,
    isFetching: queryIsFetching,
  } = useGetAdminVendorOpportunitiesQuery({
    vendorId: vendorId,
    page: page,
    limit: limit,
    filters: disambiguatedFilters,
    sort: sortValue,
    search: searchValue,
  });

  const [opportunities, setOpportunities] = useState([]);

  useEffect(() => {
    let count = opportunityData.count;
    if (count) {
      let emptyOpportunity = {
        attributes: {
          name: "",
          stage_name: "",
          total_finance_amount: "",
          financing_option_count: "",
          financing_type: "",
          has_financing_option: "",
          has_unapproved_financing_options: "",
        },
      };

      let list = [];

      for (let i = 0; i < count; i++) {
        list.push(emptyOpportunity);
      }

      let lowerLimit = (page - 1) * limit;
      let upperLimit = page * limit;
      if (list[lowerLimit]?.attributes?.name === "") {
        let i = 0;
        for (let j = lowerLimit; j < upperLimit; j++) {
          list[j] = opportunityData.opportunities.data[i];
          i = i + 1;
        }
      }

      setOpportunities(list);
    } else {
      setOpportunities([]);
    }
    setIsLoading(false);
  }, [limit, opportunityData, page]);

  useEffect(() => {
    if (queryValue && queryValue.length > 0) {
      setAppliedFilters([]);
      setDisambiguatedFilters(null);
      handleOpportunityStageRemove();
    }
  }, [handleOpportunityStageRemove, queryValue]);

  const filters = [
    {
      key: "opportunityStage",
      label: "Stage",
      filter: (
        <ChoiceList
          title="Opportunity stage"
          titleHidden
          choices={[
            { label: "Lead", value: "Lead" },
            { label: "Estimated terms", value: "Estimated terms" },
            { label: "Credit review", value: "Credit review" },
            { label: "Approved", value: "Approved" },
            { label: "Booked", value: "Booked" },
            { label: "Funded", value: "Funded" },
          ]}
          selected={opportunityStage || []}
          onChange={handleOpportunityStageChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const handleOpportunityStageRemove = useCallback(
    (value) => {
      let opportunityStageList = [...opportunityStage];
      let index = 0;
      for (let i = 0; i < opportunityStage.length; i++) {
        if (value === opportunityStage[i]) index = i;
      }
      opportunityStageList.splice(index, 1);
      setOpportunityStage(opportunityStageList);
    },
    [opportunityStage]
  );

  const handleFiltersClearAll = useCallback(() => {
    handleOpportunityStageRemove();
    handleQueryValueRemove();
  }, [handleOpportunityStageRemove, handleQueryValueRemove]);

  const filterControl =
    opportunities.length || disambiguatedFilters || queryValue ? (
      <Filters
        filters={[]}
        appliedFilters={appliedFilters}
        queryValue={queryValue}
        queryPlaceholder="Search opportunities"
        onQueryChange={handleQueryChange}
        onQueryClear={handleQueryValueRemove}
        onClearAll={handleFiltersClearAll}
      />
    ) : undefined;

  useEffect(() => {
    if (!isEmpty(opportunityStage)) {
      let disambiguatedFilterList = disambiguateLabel(
        "opportunityStage",
        opportunityStage
      );
      let filterList = [];
      const key = "opportunityStage";
      for (let i = 0; i < opportunityStage.length; i++) {
        filterList.push({
          key: opportunityStage[i],
          label: opportunityStage[i],
          onRemove: handleOpportunityStageRemove,
        });
      }
      setAppliedFilters(filterList);
      setDisambiguatedFilters(disambiguatedFilterList);
    } else {
      setAppliedFilters([]);
      setDisambiguatedFilters(null);
    }
  }, [handleOpportunityStageRemove, opportunityStage]);

  useEffect(() => {
    let filteredItems = [...opportunities];

    setFilteredItems(filteredItems);

    return () => setFilteredItems([]);
  }, [queryValue, opportunities, opportunityStage, sortValue]);

  const handleNextPage = () => {
    setIsLoading(true);
    setPage((state) => {
      if (state == Math.ceil(opportunityData.count / limit)) {
        return Math.ceil(opportunityData.count / limit);
      }
      return state + 1;
    });
  };
  const handlePreviousPage = () => {
    setIsLoading(true);
    setPage((state) => {
      if (state == 1) {
        return 1;
      }
      return state - 1;
    });
  };

  const [paginatedFilteredItems, setPaginatedFilteredItems] = useState([]);
  const [currentPageItems, setCurrentPageItems] = useState([]);
  useEffect(() => {
    const perPage = limit;

    if (filteredItems.length) {
      const paginatedFilteredItems = filteredItems.reduce(
        (resultArray, item, index) => {
          const pageIndex = Math.floor(index / perPage);
          if (!resultArray[pageIndex]) {
            resultArray[pageIndex] = [];
          }
          if (item) resultArray[pageIndex].push(item);

          return resultArray;
        },
        []
      );

      setPaginatedFilteredItems(paginatedFilteredItems);
    }

    return () => setPaginatedFilteredItems([]);
  }, [filteredItems, limit]);

  useEffect(() => {
    if (paginatedFilteredItems.length) {
      const currentPageItems = paginatedFilteredItems[page - 1] || [];
      setCurrentPageItems(currentPageItems);
    }

    return () => setCurrentPageItems([]);
  }, [paginatedFilteredItems, page]);

  const paginationMarkup = (
    <Pagination
      hasPrevious={page != 1}
      onPrevious={handlePreviousPage}
      label={`Page ${page} of ${Math.ceil(opportunityData.count / limit)}`}
      hasNext={page < Math.ceil(opportunityData.count / limit)}
      onNext={handleNextPage}
    />
  );

  const emptyStateMarkup =
    !opportunities.length &&
    !isLoading &&
    !disambiguatedFilters &&
    !queryValue ? (
      <EmptyState
        heading="Create opportunities and generate documents"
        action={{
          content: "Create opportunity",
          onAction: onCreateOpportunity,
        }}
        secondaryAction={{
          content: "Learn more",
          url: "https://help.fortifypay.com/how-to-create-an-opportunity",
          external: true,
        }}
        image={emptySearchCompanies}
      >
        <p>
          Create a new opportunity for this account to track financing options
          and documents.
        </p>
      </EmptyState>
    ) : undefined;

  const opportunitiesResourceList = (
    <ResourceList
      resourceName={resourceName}
      items={currentPageItems}
      renderItem={(item) =>
        renderItem(item, onOpportunityClick, format, isLoading)
      }
      selectedItems={selectedItems}
      onSelectionChange={setSelectedItems}
      emptyState={emptyStateMarkup}
      filterControl={filterControl}
      sortValue={sortValue}
      sortOptions={[{ label: "Most recent", value: "UPDATED_AT_DESC" }]}
      onSortChange={(selected) => {
        setSortValue(selected);
      }}
      loading={isLoading || queryIsLoading || queryIsFetching}
      flushFilters
    />
  );

  return (
    <>
      {opportunitiesResourceList}
      {paginatedFilteredItems.length > 1 && (
        <LegacyStack distribution={"center"}>{paginationMarkup}</LegacyStack>
      )}
    </>
  );
};

const disambiguateLabel = (key, value) => {
  switch (key) {
    case "opportunityStage":
      return value.map((val) => `${val}`).join(",");
    default:
      return value;
  }
};

const isEmpty = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return value === "" || value == null;
  }
};

const renderItem = (item, onOpportunityClick, format, isLoading) => {
  if (isLoading) {
    return skeletonFormat();
  }

  return itemFormat(item, onOpportunityClick);
};

const itemFormat = (item, onOpportunityClick) => {
  const {
    id,
    attributes: {
      name,
      human_stage_name,
      total_finance_amount,
      product_description,
      originator_name,
    },
  } = item;

  const formattedTotalFinanceAmount = formatCurrency(total_finance_amount);

  return (
    <ResourceItem
      id={id}
      onClick={() => onOpportunityClick(item)}
      verticalAlignment="center"
      media={<Icon source={NoteIcon} tone="subdued" />}
    >
      <InlineStack align="space-between">
        <div>
          <h3>
            <Text as="span" fontWeight="semibold" >
              <Truncate>{name}</Truncate>
            </Text>
          </h3>
          <Text variant="bodySm" as="p">
            <div style={{ maxWidth: "500px" }}>
              <Truncate>
                {originator_name || "-"} ∙ {product_description}
              </Truncate>
            </div>
          </Text>
        </div>

        <InlineStack>
          {//isDesktop
           false && (
              <div
                style={{ width: "125px", display: "flex", justifyContent: "end" }}
              >
                <Badge>{human_stage_name || "Lead"}</Badge>
              </div>
          )}

          <div style={{ width: "100px", textAlign: "right" }}>
            <p>{formattedTotalFinanceAmount}</p>
          </div>
        </InlineStack>
      </InlineStack>
    </ResourceItem>
  );
};

const skeletonFormat = () => {
  return (
    <ResourceItem
      media={<SkeletonThumbnail size="small" />}
      accessibilityLabel={`View details for ...`}
      verticalAlignment="center"
    >
      <BlockStack gap="100">
          <div style={{ width: "200px" }}>
            <SkeletonBodyText lines={1} />
          </div>
          <SkeletonBodyText lines={1} />
          <SkeletonBodyText lines={1} />
          <SkeletonBodyText lines={1} />
      </BlockStack>
    </ResourceItem>
  );
};

AdminVendorOpportunityList.propTypes = {
  format: PropTypes.string,
  stageFilters: PropTypes.array,
  onCreateOpportunity: PropTypes.func,
  onOpportunityClick: PropTypes.func,
  vendorId: PropTypes.string,
  pageLimit: PropTypes.number,
};

export default AdminVendorOpportunityList;
