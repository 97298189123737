import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from "react";
import {
  FormLayout,
  TextField,
  BlockStack,
  Select,
  Button,
  Box,
  Autocomplete,
  Icon,
  Banner,
  Modal,
  Text,
  LegacyStack,
  InlineStack,
  Tag,
  Popover,
  DatePicker,
} from "@shopify/polaris";
import {
  DuplicateIcon,
  SearchIcon,
  CalendarIcon,
} from "@shopify/polaris-icons";

import { useField, useForm, notEmpty } from "@shopify/react-form";
import PropTypes from "prop-types";
import {
  useGetClientQuery,
  useGetVendorsQuery,
  useCreateVendorPaymentMutation,
  useUpdateVendorPaymentMutation,
  useCreateOpportunityEventMutation,
} from "../../services/api";
import {
  convertServerDate,
  equipmentProductTypeOptions,
  stateOptionsShort,
  convertStateName,
  isValidCurrency,
  formatCurrency,
  formatNumericDate,
} from "../../utilities";
import { isDesktop } from "react-device-detect";
// import { CurrentTeamContext } from "../../contexts/Team";
import { CurrentContactContext } from "../../contexts/Contact";

const VendorPaymentForm = forwardRef((props, ref) => {
  const {
    clientId,
    opportunityId,
    opportunity,
    vendorId,
    vendorPayment,
    vendorPaymentId,
    onAddVendor,
    onCreateOrUpdate,
    finalInvoice,
  } = props;

  // No longer have a current team, vendor lock will need to work in another way
  // const { currentTeamId, currentTeam } = useContext(CurrentTeamContext);
  const { currentContactId } = useContext(CurrentContactContext);

  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const today = new Date();
  const [{ month, year }, setDate] = useState({
    month: today.getMonth(),
    year: today.getFullYear(),
  });
  const [selectedDate, setSelectedDate] = useState(
    finalInvoice && vendorPayment?.attributes?.invoice_due_date
      ? {
          start: today,
          end: today,
        }
      : {
          start: today,
          end: today,
        }
  );

  const [invoiceData, setInvoiceData] = useState();
  const [vendorData, setVendorData] = useState();

  const { data: client, isLoading: isLoadingAccount } = useGetClientQuery(
    clientId,
    { skip: !clientId }
  );

  const { data: vendors = [], isLoading: isLoadingVendors } =
    useGetVendorsQuery();

  const [createVendorPayment, { isLoading: isCreating }] =
    useCreateVendorPaymentMutation();
  const [updateVendorPayment, { isLoading: isUpdating }] =
    useUpdateVendorPaymentMutation();

  const invoiceNumberField = useField({
    value: vendorPayment
      ? vendorPayment.attributes.invoice_number ||
        invoiceData?.invoice_number?.value
      : "",
    validates: [
      notEmpty(`${finalInvoice ? "Invoice" : "Quote"} number is required`),
    ],
  });

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  const activator = (
    <Button onClick={togglePopoverActive} icon={CalendarIcon} />
  );

  const [invoiceDueDate, setInvoiceDueDate] = useState("");
  const [rubyStringDate, setRubyStringDate] = useState("");
  const handleDateTextChange = useCallback((value) => {
    const convertedDate = convertServerDate(value);

    const parsedDate = Date.parse(convertedDate);

    setRubyStringDate(validRubyDateFormat(convertedDate));

    if (parsedDate) {
      let date = new Date(parsedDate);
      setSelectedDate({
        start: date,
        end: date,
      });
      let month = date.getMonth();
      let year = date.getFullYear();
      setDate({ month, year });
    }
  }, []);

  useEffect(() => {
    if (vendorPayment && vendorPayment.attributes.ocr_response_normalized) {
      const { invoice: invoiceData, vendor: vendorData } =
        vendorPayment?.attributes?.ocr_response_normalized;

      setInvoiceData(invoiceData);
      setVendorData(vendorData);

      if (finalInvoice) {
        const invoiceDueDateString =
          vendorPayment?.attributes?.invoice_due_date ||
          invoiceData?.invoice_due_date?.value ||
          "";

        handleDateTextChange(invoiceDueDateString);

        const correctedDate = convertServerDate(invoiceDueDateString);

        if (correctedDate) {
          setInvoiceDueDate(formatNumericDate(correctedDate));
        }
      }
    }
  }, [vendorPayment, finalInvoice, handleDateTextChange]);

  const handleDateChange = useCallback((range) => {
    setRubyStringDate(validRubyDateFormat(range.start));
    setInvoiceDueDate(formatNumericDate(range.start));
    setSelectedDate(range);
  }, []);

  const onBlurinvoiceDueDateChange = useCallback(() => {
    setInvoiceDueDate(formatNumericDate(invoiceDueDate));
    handleDateTextChange(invoiceDueDate);
  }, [invoiceDueDate]);

  const { fields, submit, submitting, reset, submitErrors, makeClean, dirty } =
    useForm({
      fields: {
        invoice_number: invoiceNumberField,
        invoice_amount: useField(
          {
            value: vendorPayment
              ? vendorPayment.attributes.invoice_amount ||
                formatCurrency(invoiceData?.invoice_amount?.value)
              : "",
            validates: [
              notEmpty(
                `${finalInvoice ? "Invoice" : "Quote"} amount is required`
              ),
              (value) => {
                if (invoiceNumberField.value || value) {
                  return isValidCurrency(value);
                }
              },
            ],
          },
          [invoiceNumberField.value]
        ),
        location_address: useField({
          value: vendorPayment
            ? vendorPayment.attributes.location_address ||
              invoiceData?.location_address?.value
            : "",
          validates: [notEmpty("Product address is required")],
        }),
        location_city: useField({
          value: vendorPayment
            ? vendorPayment.attributes.location_city ||
              invoiceData?.location_city?.value
            : "",
          validates: [notEmpty("Product city is required")],
        }),
        location_state: useField({
          value: vendorPayment
            ? vendorPayment.attributes.location_state ||
              invoiceData?.location_state?.value
            : "",
          validates: [notEmpty("Product state is required")],
        }),
        location_zip: useField({
          value: vendorPayment
            ? vendorPayment.attributes.location_zip ||
              invoiceData?.location_zip?.value
            : "",
          validates: [notEmpty("Product zip code is required")],
        }),
        location_is_hq_address: useField({
          value: vendorPayment
            ? vendorPayment.attributes.location_is_hq_address
            : false,
          validates: [],
        }),
        product_type: useField({
          value: vendorPayment
            ? vendorPayment.attributes.product_type ||
              invoiceData?.product_type?.value
            : "",
          validates: [],
        }),
        product_description: useField({
          value: vendorPayment
            ? vendorPayment.attributes.product_description ||
              invoiceData?.product_description?.value
            : "",
          validates: [],
        }),
        sales_tax: useField({
          value: vendorPayment
            ? vendorPayment.attributes.sales_tax ||
              formatCurrency(invoiceData?.sales_tax?.value)
            : "",
          validates: [],
        }),
        vendor_name: useField({
          value: "",
          validates: [notEmpty("Vendor is required")],
        }),
      },
      async onSubmit(form) {
        delete form.vendor_name;

        let vendorPaymentBodyPayload = {
          ...form,
          vendor_id: selectedVendorId,
        };

        // Add Final Invoice indicator
        if (finalInvoice) {
          vendorPaymentBodyPayload = {
            ...vendorPaymentBodyPayload,
            is_final_invoice: true,
            invoice_due_date: rubyStringDate,
          };
        }

        if (
          vendorPayment &&
          Object.keys(vendorPayment.attributes).length !== 0
        ) {
          return updateVendorPayment({
            id: vendorPayment.id,
            opportunityId: opportunityId,
            ...vendorPaymentBodyPayload,
          })
            .unwrap()
            .then(() => {
              // Call event for opportunity vendor quote updated
              callOpportunityVendorQuoteEvent();
              onCreateOrUpdate();
              return { status: "success" };
            })
            .catch((error) => {
              console.log(error);
              setShowErrorBanner(true);
              return {
                status: "fail",
                errors: [
                  {
                    message: `Could not update vendor ${
                      finalInvoice ? "invoice" : "quote"
                    }`,
                  },
                ],
              };
            });
        } else {
          return createVendorPayment({
            opportunityId: opportunityId,
            ...vendorPaymentBodyPayload,
          })
            .unwrap()
            .then(() => {
              onCreateOrUpdate();
              return { status: "success" };
            })
            .catch((error) => {
              console.log(error);
              setShowErrorBanner(true);
              return {
                status: "fail",
                errors: [
                  {
                    message: `Could not create vendor ${
                      finalInvoice ? "invoice" : "quote"
                    }`,
                  },
                ],
              };
            });
        }
      },
    });

  const [vendorPaymentFormDirty, setVendorPaymentFormDirty] = useState(false);

  const handleCopyAccountAddress = () => {
    if (client?.attributes) {
      fields.location_address.newDefaultValue(client.attributes.billing_street);
      fields.location_city.newDefaultValue(client.attributes.billing_city);
      fields.location_state.newDefaultValue(
        client.attributes.billing_state_abbreviated ||
          convertStateName(client.attributes.billing_state, 2)
      );

      fields.location_zip.newDefaultValue(
        client.attributes.billing_postal_code
      );

      fields.location_is_hq_address.newDefaultValue(true);

      setVendorPaymentFormDirty(true);
    }
  };

  const [createOpportunityEvent, { isLoading: isCreatingOpportunityEvent }] =
    useCreateOpportunityEventMutation();

  const callOpportunityVendorQuoteEvent = () => {
    return createOpportunityEvent({
      opportunityId: opportunityId,
      // type: "OpportunityVendorQuoteUpdated",
      type: "VendorPaymentUpdated",
      contact_id: currentContactId,
    })
      .unwrap()
      .then()
      .catch((error) => {
        console.log(error);
      });
  };

  useImperativeHandle(ref, () => ({
    handleSubmitForm() {
      handleUpdateOrSubmit();
    },
    hasFieldErrors: () => {
      const fieldsData = Object.entries(fields).map(([key, value]) => value);
      const hasErrors = fieldsData.some((field) => field.error);
      return hasErrors;
    },
    fieldErrors: () => {
      const fieldsData = Object.entries(fields).map(([key, value]) => value);
      return fieldsData
        .map((field) => {
          return field.allErrors.map((error) => error);
        })
        .flat();
    },
  }));

  const handleUpdateOrSubmit = () => {
    if (vendorPaymentId || vendorPayment?.id) {
      if (fields.invoice_amount.dirty || fields.sales_tax.dirty) {
        handleShowUpdateVendorPaymentConfirmationModal();
      } else {
        submit();
      }
    } else if (
      finalInvoice &&
      opportunity.attributes.total_finance_amount != fields.invoice_amount
    ) {
      handleShowDifferentInvoiceAmountModal();
    } else {
      submit();
    }
  };

  const deselectedVendorOptions = useMemo(() => {
    return vendors.map((vendor) => ({
      value: vendor.attributes.name,
      label: vendor.attributes.name,
    }));
  }, [vendors]);
  const [selectedVendorOptions, setSelectedVendorOptions] = useState([]);
  const [selectedVendorId, setSelectedVendorId] = useState(
    vendorPayment?.attributes?.vendor_id
  );
  const [vendorInputValue, setVendorInputValue] = useState(undefined);
  const [vendorOptions, setVendorOptions] = useState(deselectedVendorOptions);

  const updateVendorText = useCallback(
    (value) => {
      setVendorInputValue(value);

      if (value === "") {
        setVendorOptions(deselectedVendorOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedVendorOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setVendorOptions(resultOptions);
    },
    [deselectedVendorOptions]
  );

  useEffect(() => {
    // if (currentTeam.attributes.vendor_locked) {
    //   setSelectedVendorId(currentTeam.attributes.locked_vendor_id)
    // } else {
    if (vendorId || vendorPayment?.attributes?.vendor_id) {
      const selectedVendor = vendors.find((vendor) => {
        return (
          vendor.id == vendorId ||
          vendor.id == vendorPayment?.attributes?.vendor_id
        );
      });
      selectedVendor && setSelectedVendorId(selectedVendor.id);
    } else if (
      vendorData?.name?.value &&
      !vendorPayment?.attributes?.vendor_id
    ) {
      const selectedVendor = vendors.find((vendor) => {
        const lowercaseVendorName = vendorData?.name?.value
          ? vendorData?.name?.value.toLowerCase()
          : undefined;

        return vendor.attributes.name
          .toLowerCase()
          .includes(lowercaseVendorName);
      });
      selectedVendor && setSelectedVendorId(selectedVendor.id);
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorId, vendors, vendorData]);

  useEffect(() => {
    const selectedVendor = vendors.find((vendor) => {
      return vendor.id == selectedVendorId;
    });

    const vendorName = selectedVendor?.attributes?.name;

    if (vendorName) {
      setSelectedVendorOptions([vendorName]);
      setVendorInputValue(vendorName);
      fields.vendor_name.newDefaultValue(vendorName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVendorId, vendors]);

  const updateVendorSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = vendorOptions.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption && matchedOption.label;
      });

      const selectedVendor = vendors.find((vendor) => {
        return vendor.attributes.name == selected[0];
      });

      selectedVendor && setSelectedVendorId(selectedVendor.id);

      setSelectedVendorOptions(selected);
      setVendorInputValue(selectedValue[0]);
      fields.vendor_name.newDefaultValue(selectedValue[0]);
    },
    [fields.vendor_name, vendorOptions, vendors]
  );

  const validRubyDateFormat = (date) => {
    let formatDate = new Date(date);
    let day = formatDate.getDate();
    let month = formatDate.getMonth();
    let year = formatDate.getFullYear();
    return `${year}-${month + 1}-${day}`;
  };

  useEffect(() => {
    if (vendorPayment?.id) {
      if (vendorPayment.attributes.vendor_id) {
        setVendorInputValue(vendorPayment.attributes.vendor_name);
      }
    }
  }, [vendorData?.name?.value, vendorPayment]);

  const removeVendorTag = useCallback(
    (tag) => (event) => {
      event.stopPropagation();
      const options = [...selectedVendorOptions];
      options.splice(options.indexOf(tag), 1);
      setSelectedVendorOptions(options);
      setVendorInputValue(undefined);
      setSelectedVendorId(undefined);
      fields.vendor_name.newDefaultValue(undefined);
    },
    [fields.vendor_name, selectedVendorOptions]
  );

  const vendorAutocompleteTextField =
    selectedVendorOptions.length > 0 ? (
      <LegacyStack spacing="extraTight" alignment="center">
        {selectedVendorOptions.map((option) => {
          return (
            <Tag
              key={`option${option}`}
              onRemove={
                removeVendorTag(option)
                // currentTeam.attributes.vendor_locked
                //   ? null
                //   : removeVendorTag(option)
              }
            >
              <p>{option}</p>
            </Tag>
          );
        })}
      </LegacyStack>
    ) : (
      <Autocomplete.TextField
        {...fields.vendor_name}
        onChange={(value) => {
          fields.vendor_name.onChange(value);
          updateVendorText(value);
        }}
        value={vendorInputValue}
        prefix={<Icon source={SearchIcon} tone="base" />}
        placeholder="Search"
      />
    );

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const errorBanner = submitErrors.length > 0 && showErrorBanner && (
    <Box>
      <BlockStack>
        <Banner tone="critical" title="Something went wrong">
          {submitErrors.map((error, index) => {
            return <p key={index}>{error.message}</p>;
          })}
        </Banner>
      </BlockStack>
    </Box>
  );

  const [
    showUpdateVendorPaymentConfirmationModal,
    setShowUpdateVendorPaymentConfirmationModal,
  ] = useState(false);
  const handleShowUpdateVendorPaymentConfirmationModal = () => {
    setShowUpdateVendorPaymentConfirmationModal(true);
  };
  const handleCloseModal = () => {
    setShowUpdateVendorPaymentConfirmationModal(false);
  };
  const updateVendorPaymentConfirmationModal = (
    <Modal
      open={showUpdateVendorPaymentConfirmationModal}
      onClose={handleCloseModal}
      title={`Update vendor ${finalInvoice ? "invoice" : "quote"}`}
      primaryAction={{
        content: "Update",
        onAction: submit,
        isLoading: submitting,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseModal,
        },
      ]}
    >
      <Modal.Section title={"Are you sure?"}>
        <BlockStack>
          <Text variant="headingMd" as="h2">
            Are you sure?
          </Text>
          <p>
            Updating the vendor {finalInvoice ? "invoice" : "quote"} amount or
            sales tax will prompt the finance options to be recalculated.
          </p>
        </BlockStack>
      </Modal.Section>
    </Modal>
  );

  const [showDifferentInvoiceAmountModal, setShowDifferentInvoiceAmountModal] =
    useState(false);
  const handleShowDifferentInvoiceAmountModal = () => {
    setShowDifferentInvoiceAmountModal(true);
  };
  const handleCloseShowDifferentInvoiceAmountModal = () => {
    setShowDifferentInvoiceAmountModal(false);
  };
  const differentInvoiceAmountModal = (
    <Modal
      open={showDifferentInvoiceAmountModal}
      onClose={handleCloseShowDifferentInvoiceAmountModal}
      title={`Invoice Upload`}
      primaryAction={{
        content: "Confirm",
        onAction: submit,
        isLoading: isCreating,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseShowDifferentInvoiceAmountModal,
        },
      ]}
    >
      <Modal.Section title={"Are you sure?"}>
        <BlockStack>
          <Text variant="headingMd" as="h2">
            Are you sure?
          </Text>
          <p>
            We notice this invoice amount is different from the total quote
            amount. Updating the invoice amount will prompt any associated
            transactions to be recalculated according to the total invoice
            amount.
          </p>
        </BlockStack>
      </Modal.Section>
    </Modal>
  );

  const datePopover = (
    <Popover
      active={popoverActive}
      activator={activator}
      onClose={togglePopoverActive}
      ariaHaspopup={false}
      sectioned
    >
      <DatePicker
        month={month}
        year={year}
        onChange={handleDateChange}
        onMonthChange={handleMonthChange}
        selected={selectedDate}
        // disableDatesBefore={finalInvoice ? new Date(today.setHours(0,0,0,0)) : null}
      />
    </Popover>
  );

  const emptyState = vendorInputValue && vendorInputValue.length > 1 && (
    <>
      <Icon source={SearchIcon} />
      <div style={{ textAlign: "center", padding: "12px" }}>
        <BlockStack>
          <p>{`Could not find any results for "${vendorInputValue}".`}</p>
        </BlockStack>
      </div>
    </>
  );

  return (
    <>
      {errorBanner}

      <Box as="section">
        <Box paddingBlockEnd="400">
          <InlineStack align="space-between">
            <Text variant="headingMd" as="h6">
              Vendor
            </Text>
            <Button
              variant={"plain"}
              onClick={() => onAddVendor(vendorInputValue)}
            >
              Add new vendor
            </Button>
          </InlineStack>
        </Box>
        <Autocomplete
          options={vendorOptions}
          selected={selectedVendorOptions}
          onSelect={updateVendorSelection}
          textField={vendorAutocompleteTextField}
          loading={isLoadingVendors}
          emptyState={emptyState}
          preferredPosition={"below"}
        />
      </Box>

      <Box as="section">
        <Box paddingBlockStart="400">
          <Text variant="headingMd" as="h6">
            Product details
          </Text>
        </Box>
        <br />
        <FormLayout>
          {finalInvoice && (
            <TextField
              label={`Invoice due date`}
              value={invoiceDueDate}
              onChange={setInvoiceDueDate}
              onBlur={onBlurinvoiceDueDateChange}
              autoComplete="off"
              requiredIndicator
              connectedRight={datePopover}
            />
          )}
          <TextField
            label={`${finalInvoice ? "Invoice" : "Quote"} number`}
            autoComplete="off"
            requiredIndicator
            {...fields.invoice_number}
          />
          <FormLayout.Group>
            <TextField
              label={`${finalInvoice ? "Invoice" : "Quote"} total amount`}
              type="currency"
              autoComplete="off"
              requiredIndicator
              {...fields.invoice_amount}
            />
            <TextField
              label={"Sales tax"}
              type="currency"
              autoComplete="off"
              // requiredIndicator
              {...fields.sales_tax}
            />
          </FormLayout.Group>
        </FormLayout>
      </Box>

      <Box as="section">
        <FormLayout>
          <Select
            label="Product type"
            placeholder={"Select product type"}
            options={equipmentProductTypeOptions}
            autoComplete="off"
            {...fields.product_type}
          />
          <TextField
            label={"Product description"}
            maxHeight={"154px"}
            multiline={3}
            autoComplete="off"
            {...fields.product_description}
          />
        </FormLayout>
      </Box>

      <Box>
        <Box paddingBlockStart="400" paddingBlockEnd="400">
          <Text variant="headingMd" as="h6">
            Location of product
          </Text>
        </Box>
        <FormLayout>
          <Button
            icon={DuplicateIcon}
            onClick={handleCopyAccountAddress}
            loading={isLoadingAccount}
            disabled={!client?.attributes}
          >
            Copy from account profile
          </Button>

          <FormLayout.Group>
            <TextField
              requiredIndicator
              label={"Street"}
              autoComplete="off"
              {...fields.location_address}
            />
          </FormLayout.Group>

          <FormLayout.Group condensed={isDesktop}>
            <TextField
              requiredIndicator
              label="City"
              autoComplete="off"
              {...fields.location_city}
            />
            <Select
              label="State"
              placeholder={"Select state"}
              autoComplete="off"
              requiredIndicator
              options={stateOptionsShort}
              {...fields.location_state}
            />
            <TextField
              requiredIndicator
              label="Zip code"
              autoComplete="off"
              {...fields.location_zip}
            />
          </FormLayout.Group>
        </FormLayout>
      </Box>
      {updateVendorPaymentConfirmationModal}
      {differentInvoiceAmountModal}
    </>
  );
});
VendorPaymentForm.displayName = "VendorPaymentForm";

VendorPaymentForm.propTypes = {
  vendorPayment: PropTypes.object,
  vendorPaymentId: PropTypes.string,
  vendorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  opportunityId: PropTypes.string,
  opportunity: PropTypes.object,
  onAddVendor: PropTypes.func,
  onCreateOrUpdate: PropTypes.func,
  finalInvoice: PropTypes.bool,
};

export default VendorPaymentForm;
