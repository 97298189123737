import React, { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";

import {
  Text,
  BlockStack,
  Banner,
  Spinner,
  Link,
  Card,
  FormLayout,
  Button,
  Bleed,
  Divider,
} from "@shopify/polaris";

import FadeIn from "../../../../../components/FadeIn";
import PaymentScheduleTable from "../../components/PaymentScheduleTable";
import CustomerCardSetupForm from "../../../../../components/Stripe/CustomerCardSetupForm";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import {
  useGetOpportunityQuery,
  useGetStripeSetupIntentQuery,
  useGetVendorPaymentsQuery,
  useGetVendorProgramQuery,
  useGetFinancingOptionsQuery,
  useGetOpportunityTransactionsQuery,
  useGetStripeListPaymentMethodsQuery,
  useCreateOpportunityPaymentAuthorizationMutation,
} from "../../../../../services/api";
import { formatShortDate } from "../../../../../utilities";

const PaymentSchedule = (props) => {
  const {
    opportunityId,
    setCanClickNext,
  } = props;

  // TODO - I don't think these are different, so they should not be separate
  const [stripeVendorId, setStripeVendorId] = useState(null);
  const [stripeAccountId, setStripeAccountId] = useState(null);

  const [showGeneralBanner, setShowGeneralBanner] = useState(false);
  const [generalBannerStatus, setGeneralBannerStatus] = useState("success");
  const [generalBannerMessage, setGeneralBannerMessage] = useState("");

  const [errorBannerMessage, setErrorBannerMessage] = useState("");
  const [showGenerateErrorBanner, setShowGenerateErrorBanner] = useState(false);

  const [forceShowCard, setForceShowCard] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const [finalInvoiceVendorPayment, setFinalInvoiceVendorPayment] =
    useState(null);
  const [orderedOpportunityTransactions, setOrderedOpportunityTransactions] =
    useState([]);

  const [uniqueElementsKey, setUniqueElementsKey] = useState(Date.now());

  // We need to re-query the opportunity to get the latest designated signer ID
  // because the designated signer ID is updated in the previous step, and the
  // design of the parent component does not update the PaymentSchedule component instance
  const {
    data: opportunity = { attributes: {} },
    isLoading: isLoadingOpportunity,
  } = useGetOpportunityQuery(opportunityId, { skipCache: true });

  const [designatedSignerId, setDesignatedSignerId] = useState();
  useEffect(() => {
    if (opportunity.attributes.designated_signer_id)
      setDesignatedSignerId(opportunity.attributes.designated_signer_id);
  }, [opportunity]);

  const { data: vendorPayments = [], isLoading: isLoadingVendorPayments } =
    useGetVendorPaymentsQuery(
      {
        opportunityId: opportunityId,
        finalInvoice: opportunity?.attributes?.final_invoice_ids.length > 0,
      },
      { skip: !opportunity }
    );

  const {
    data: vendorProgram = { attributes: {} },
    isLoading: isLoadingVendorProgram,
  } = useGetVendorProgramQuery(
    { id: opportunity.attributes.primary_vendor_program_id },
    { skip: !opportunity.attributes.primary_vendor_program_id }
  );

  const {
    data: stripeSetupIntent,
    isLoading: isLoadingStripeSetupIntent,
    error: stripeSetupIntentError,
  } = useGetStripeSetupIntentQuery(
    {
      contactId: designatedSignerId,
      vendorId: stripeVendorId,
      opportunityId: opportunityId,
    },
    {
      skip: !stripeVendorId || !designatedSignerId || !opportunityId,
    }
  );

  const { data: financingOptions = [], isLoading: isLoadingFinancingOptions } =
    useGetFinancingOptionsQuery(opportunityId);

  const {
    data: opportunityTransactions = [],
    isLoading: isLoadingOpportunityTransactions,
  } = useGetOpportunityTransactionsQuery(opportunityId);

  const {
    data: stripePaymentMethodList = [],
    isLoading: isLoadingPaymentMethods,
    isFetching: isFetchingPaymentMethods,
  } = useGetStripeListPaymentMethodsQuery(
    {
      contactId: designatedSignerId,
      vendorId: stripeVendorId,
    },
    { skip: !designatedSignerId || !stripeVendorId }
  );

  const [
    createOpportunityPaymentAuthorization,
    { isLoading: isCreatingOpportunityPaymentAuthorization },
  ] = useCreateOpportunityPaymentAuthorizationMutation();

  useEffect(() => {
    for (let i = 0; i < financingOptions.length; i++) {
      if (financingOptions[i].attributes.selected) {
        setSelectedOption(financingOptions[i]);
        break;
      }
    }
  }, [financingOptions]);

  useEffect(() => {
    // QUESTION/TODO: What if there are multiple vendor payments with different vendor IDs (?)
    if (vendorPayments.length > 0) {
      let currentFinalInvoice = undefined;
      let currentStripeVendorId = undefined;

      // First check if there is a final invoice
      for (let i = 0; i < vendorPayments.length; i++) {
        if (opportunity.attributes.final_invoice_ids?.includes(vendorPayments[i].id)) {
          currentFinalInvoice = vendorPayments[i];
          currentStripeVendorId = vendorPayments[i].attributes.vendor_id;
          break;
        } else {
          if (vendorPayments[i].attributes.vendor_stripe_connected_account_id) {
            currentFinalInvoice = vendorPayments[i];
            currentStripeVendorId = vendorPayments[i].attributes.vendor_id;
          }
        }
      }

      if (!currentFinalInvoice || !currentStripeVendorId) {
        // If no stripe ID exists at all, set to zero and let it error
        currentFinalInvoice = vendorPayments[0];
        currentStripeVendorId = vendorPayments[0].attributes.vendor_id;
      }

      setStripeVendorId(currentStripeVendorId);
      setFinalInvoiceVendorPayment(currentFinalInvoice);
    }
  }, [vendorPayments]);

  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (stripeAccountId) {
      const promise = loadStripe(`${process.env.STRIPE_PUBLISHABLE_KEY}`, {
        stripeAccount: stripeAccountId ? stripeAccountId : '',
      });
      setStripePromise(promise);
    } else {
      const promise = loadStripe(`${process.env.STRIPE_PUBLISHABLE_KEY}`);
      setStripePromise(promise);
    }
  }, [stripeAccountId]);

  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    if (stripeSetupIntent) {
      setClientSecret(stripeSetupIntent.attributes.client_secret);
    }
  }, [stripeSetupIntent]);

  useEffect(() => {
    if (opportunityTransactions.length > 0) {
      let orderedList = [...opportunityTransactions];
      orderedList.sort((a, b) => {
        let paidDateA = a?.attributes?.scheduled_date
          ? new Date(a.attributes.scheduled_date)
          : finalInvoiceVendorPayment?.attributes?.invoice_due_date
          ? new Date(finalInvoiceVendorPayment.attributes.invoice_due_date)
          : new Date(); // Default value

        let paidDateB = b?.attributes?.scheduled_date
          ? new Date(b.attributes.scheduled_date)
          : finalInvoiceVendorPayment?.attributes?.invoice_due_date
          ? new Date(finalInvoiceVendorPayment.attributes.invoice_due_date)
          : new Date(); // Default value

        return paidDateA - paidDateB;
      });

      setOrderedOpportunityTransactions(orderedList);
    }
  }, [opportunityTransactions, finalInvoiceVendorPayment]);

  const handleAuthorizePayments = useCallback(() => {
    createOpportunityPaymentAuthorization({
      opportunityId: opportunityId,
    })
      .then()
      .catch((error) => {
        console.log(error);
      });
  }, [createOpportunityPaymentAuthorization, opportunityId]);

  const LoadingSpinnerWithPadding = () => {
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "304px",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "calc(50% - 2.5rem)",
            top: "7.5rem",
          }}
        >
          <Spinner size="large" />
        </div>
      </div>
    );
  };

  const errorBanner = (error) => {
    return (
      <Banner
        title="There was an error saving this payment method:"
        tone="critical"
      >
        {error}
      </Banner>
    );
  };

  if (
    isLoadingOpportunity ||
    isLoadingVendorProgram ||
    isLoadingStripeSetupIntent
  )
    return <LoadingSpinnerWithPadding />;

  return (
    <FadeIn fadeIn>
      <BlockStack gap={"400"}>
        <Text variant="headingXl" as="h4">
          Authorize payments
        </Text>

        {stripeSetupIntentError &&
          errorBanner(stripeSetupIntentError.data.error)}

        {showGenerateErrorBanner && errorBanner(errorBannerMessage)}

        {showGeneralBanner && (
          <Banner title={generalBannerMessage} tone={generalBannerStatus} />
        )}

        {clientSecret && stripePromise && (
          <Elements
            key={uniqueElementsKey}
            stripe={stripePromise}
            options={{ clientSecret: clientSecret }}
          >
            <CustomerCardSetupForm
              opportunityId={opportunityId}
              opportunityStageName={opportunity.attributes.stage_name}
              designatedSignerId={designatedSignerId}
              vendorId={stripeVendorId}
              clientSecret={clientSecret}
              forceShowCard={forceShowCard}
              paymentsAuthorizedAt={
                opportunity.attributes.payments_authorized_at
              }
              setForceShowCard={setForceShowCard}
              setClientSecret={setClientSecret}
              setUniqueElementsKey={setUniqueElementsKey}
              setErrorBannerMessage={setErrorBannerMessage}
              setShowGenerateErrorBanner={setShowGenerateErrorBanner}
              setShowGeneralBanner={setShowGeneralBanner}
              setGeneralBannerMessage={setGeneralBannerMessage}
              setGeneralBannerStatus={setGeneralBannerStatus}
              setCanClickNext={setCanClickNext}
              stripeSetupIntent={stripeSetupIntent}
              stripePaymentMethodList={stripePaymentMethodList}
            />
          </Elements>
        )}

        <Card>
          <BlockStack gap="400">
            <Text variant="headingMd" as="h6">
              Payment schedule
            </Text>
            <PaymentScheduleTable
              opportunity={opportunity}
              financingOption={selectedOption}
              orderedOpportunityTransactions={orderedOpportunityTransactions}
              showSchedule={true}
            />
          </BlockStack>
        </Card>

        <Link
          url="https://help.fortifypay.com/kb-tickets/new"
          external
          removeUnderline
        >
          Questions about payment methods? Contact support
        </Link>

        {opportunity.attributes.payments_authorized_at ? (
          <Text>
            Payments authorized on{" "}
            {formatShortDate(
              new Date(opportunity.attributes.payments_authorized_at)
            )}
          </Text>
        ) : (
          <FormLayout>
            <Button
              variant={"primary"}
              loading={isCreatingOpportunityPaymentAuthorization}
              onClick={handleAuthorizePayments}
              disabled={stripePaymentMethodList.length === 0}
            >
              Authorize payments
            </Button>
          </FormLayout>
        )}
      </BlockStack>
    </FadeIn>
  );
};

PaymentSchedule.propTypes = {
  opportunityId: PropTypes.string,
  setCanClickNext: PropTypes.func,
};

export default PaymentSchedule;
