import React from "react";

import {
  Bleed,
  Text,
  Link,
  Icon,
  Divider,
  Card,
  BlockStack,
  InlineStack,
} from "@shopify/polaris";

import Opportunity from "../../types/Opportunity";

import { formatCurrency } from "../../utilities";

type Props = {
  opportunity: Opportunity;
  buyRate: number;
  sellRate: number;
  customerInterestRate: number;
  blindDiscount: number;
  sellRateSpread: number;
  netPayment: number;

  // discountAmount: number;
  // discountPercent: number;
  fortSpreadAmount: number;
  fortSpreadPercent: number;
  
};

const FinanceSummaryCard = (props: Props) => {
  const {
    buyRate,
    sellRate,
    customerInterestRate,
    opportunity,
    blindDiscount = 0,
    sellRateSpread = 0,
    netPayment,
    // discountAmount,
    // discountPercent,
    fortSpreadAmount,
    fortSpreadPercent,
  } = props;

  const blindDiscountPercent = blindDiscount / parseFloat(opportunity.attributes.total_finance_amount) * 100;
  const blindDiscountPercentRounded = Math.round(blindDiscountPercent * 100) / 100;

  const sellRateSpreadPercent = sellRateSpread / parseFloat(opportunity.attributes.total_finance_amount) * 100;
  const sellRateSpreadPercentRounded = Math.round(sellRateSpreadPercent * 100) / 100;

  return (
    <Card padding="400">
      <BlockStack gap="400">
        <Text variant="headingMd" as="h6">
          Finance Summary
        </Text>

        <BlockStack gap="200">
          <InlineStack align="space-between">
            <Text as="p">
              Buy rate
            </Text>

            <Text as="p">
              {buyRate}%
            </Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p">
              Sell rate
            </Text>

            <Text as="p">
              {sellRate}%
            </Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p">
              Customer rate
            </Text>

            <Text as="p">
              {customerInterestRate}%
            </Text>
          </InlineStack>
        </BlockStack>

        <Bleed marginInline={"400"}>
          <Divider />
        </Bleed>

        <BlockStack gap="200">
          <InlineStack align="space-between">
            <Text as="p">
              Total Invoice
            </Text>

            <Text as="p">
              {formatCurrency(opportunity.attributes.total_finance_amount)}
            </Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p">
              Blind discount
            </Text>

            <Text as="p">
              {formatCurrency(blindDiscount)}
            </Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p" tone="subdued">
              Blind discount %
            </Text>

            <Text as="p" tone="subdued">
              {blindDiscountPercentRounded}%
            </Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p">
              Net payment
            </Text>

            <Text as="p">
              {formatCurrency(netPayment)}
            </Text>
          </InlineStack>
        </BlockStack>
        <Bleed marginInline={"400"}>
          <Divider />
        </Bleed>

        <BlockStack gap="200">
          <InlineStack align="space-between">
            <Text fontWeight="bold" as="p">
              Spread
            </Text>

            <Text fontWeight="bold" as="p">
              {formatCurrency(sellRateSpread)}
            </Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p" tone="subdued">
              Spread %
            </Text>

            <Text as="p" tone="subdued">
              {sellRateSpreadPercentRounded}%
            </Text>
          </InlineStack>
        </BlockStack>
      </BlockStack>
    </Card>
  );
};

export default FinanceSummaryCard;
