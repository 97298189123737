import React, { useEffect, useState, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Page,
  Layout,
  Text,
  Tabs
} from "@shopify/polaris";

import {
  useGetVendorsQuery,
  useGetVendorAdminsQuery
} from "../../../services/api";

import FadeIn from "../../../components/FadeIn";
import LoadingScreen from "../../../components/Auth/Loading";
import VendorAdminSettingsAccountDetailsTab from "./Tabs/AccountDetails";
import VendorAdminListTab from "./Tabs/VendorAdminList";
import VendorAdminIntegratedPaymentsSettings from "./Tabs/IntegratedPaymentsSettings";

import { CurrentContactContext } from "../../../contexts/Contact";

const VendorAdminSettingsShow = (props) => {
  const { id: vendorId } = useParams();

  const { currentContact } = useContext(CurrentContactContext)

  const { data: vendors = [], isLoading: isLoadingVendors } =
    useGetVendorsQuery();

  const { data: admins = [], isLoading: queryIsLoading } =
    useGetVendorAdminsQuery({ vendorId: vendorId });

  const [currentVendor, setCurrentVendor] = useState();

  useEffect(() => {
    if(vendors.length > 0) {
      for(let i = 0; i < vendors.length; i++) {
        if(vendors[i].id === vendorId) {
          setCurrentVendor(vendors[i])
          break
        }
      }
    }
  }, [vendors])

  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    [],
  );

  const tabs = [
    {
      id: 'vendor-admins',
      content: 'Vendor Admins',
      accessibilityLabel: 'Vendor Admins',
      panelID: 'vendor-admins',
      tab: (
        <VendorAdminListTab
          vendorId={vendorId}
        />
      )
    },
    {
      id: 'account-details',
      content: 'Account Details',
      accessibilityLabel: 'Account Details',
      panelID: 'account-details',
      tab: <VendorAdminSettingsAccountDetailsTab vendor={currentVendor} />
    },
    {
     id: 'integrated-payments-settings',
     content: 'Integrated payments settings',
     accessibilityLabel: 'Integrated payments settings',
     panelID: 'integrated-payments-settings',
     tab: <VendorAdminIntegratedPaymentsSettings vendor={currentVendor} />
    },
    //{
    //  id: 'program-details',
    //  content: 'Program Details',
    //  accessibilityLabel: 'Program Details',
    //  panelID: 'program-details',
    //  tab: <p>Program Details</p>
    //},
    //{
    //  id: 'payments-setup',
    //  content: 'Payments Setup',
    //  accessibilityLabel: 'Payments Setup',
    //  panelID: 'payments-setup',
    //  tab: <p>Payments Setup</p>
    //},
  ];

  const pageIndexMarkup = (
    <>
      <FadeIn fadeIn>
        <Page
          fullWidth
          title={currentVendor ? currentVendor.attributes.name : "Vendor Settings"}
          backAction={{content: "Vendor Settings", url: '/vendor_settings'}}
        >
          <Layout>
            <Layout.Section>
              <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                {tabs[selected].tab}
              </Tabs>
            </Layout.Section>
          </Layout>
        </Page>
      </FadeIn>
    </>
  );

  return currentContact ? pageIndexMarkup : <LoadingScreen />;
};

export default VendorAdminSettingsShow;
