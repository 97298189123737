import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  IndexTable,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  Pagination,
  LegacyStack,
  Link,
  Badge,
} from "@shopify/polaris";
import PropTypes from "prop-types";
import { useDebounce } from "use-debounce";
import {
  useGetAdminContactsQuery
} from "../../services/api";

const ContactAdminList = (props) => {
  const { itemLimit = 20, onRowClick } = props;

  const location = useLocation();
  const history = useHistory();

  const [selected, setSelected] = useState(0);

  const [limit, setLimit] = useState(itemLimit);
  const [page, setPage] = useState(1);
  const [queryValue, setQueryValue] = useState(undefined);
  const [searchValue] = useDebounce(queryValue, 500);
  const [sortValue, setSortValue] = useState("UPDATED_AT_DESC");
  const [sortDirection, setSortDirection] = useState("descending");
  const [disambiguatedFilters, setDisambiguatedFilters] = useState(null);

  const [taggedWith, setTaggedWith] = useState(undefined);
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const [originatorSearch] = useDebounce(taggedWith, 500);

  const {
    data: contactData = { length: 0 },
    isLoading: queryIsLoading,
    isFetching: queryIsFetching,
    refetch: refetchContacts
  } = useGetAdminContactsQuery({
    page: page,
    limit: limit,
    search: searchValue
  });

  const { mode, setMode } = useSetIndexFiltersMode();

  const [contacts, setContacts] = useState([]);
  const [currentlyDisplayedContacts, setCurrentlyDisplayedContacts] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let count = contactData.length;
    if (count) {
      let emptyContact = {
        attributes: {
          full_name: "",
          email: "",
        },
      };

      let list = [];

      for (let i = 0; i < count; i++) {
        list.push(emptyContact);
      }

      let lowerLimit = (page - 1) * limit;
      let upperLimit = page * limit;

      if (list[lowerLimit]?.attributes?.full_name === "") {
        let i = lowerLimit;
        for (let j = 0; j < itemLimit; j++) {
          if (contactData.contacts.data[j]) {
            list[i] = contactData.contacts.data[j];
          }
          i = i + 1;
        }
      }

      let updateList = list.slice(lowerLimit, upperLimit);

      setContacts(list);
      setCurrentlyDisplayedContacts(updateList);

      setIsLoading(false);
    } else {
      setContacts([]);
      setIsLoading(false);
    }
  }, [contactData, page]);

  useEffect(() => {
    refetchContacts()
  }, [page])

  const resourceName = {
    singular: "contact",
    plural: "contacts",
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState([]);

  const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  const handleQueryValueRemove = () => setQueryValue(undefined);
  const handleClearAll = useCallback(() => {
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleTaggedWithRemove]);
  const handleSortChange = useCallback((value) => setSortValue(value), []);

  const filters = [];
  const appliedFilters = [];

  const sortOptions = [{ label: "Most recent", value: "UPDATED_AT_DESC" }];

    const rowMarkup = currentlyDisplayedContacts.map((item, index) => (
      <IndexTable.Row id={index} key={index} position={index}>
        <IndexTable.Cell>
          <div>
            <Text as="span" fontWeight="semibold" >
              <Link
                monochrome
                removeUnderline
                onClick={() => onRowClick(item)}
              >
                {item.attributes.full_name}
              </Link>
            </Text>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>{item.attributes.email}</IndexTable.Cell>
      </IndexTable.Row>
    ));

  function disambiguateLabel(key, value) {
    switch (key) {
      case "originator":
        return `Originator: ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  const handleNextPage = () => {
    setIsLoading(true);
    setPage((state) => {
      if (state == Math.ceil(contactData.length / limit)) {
        return Math.ceil(contactData.length / limit);
      }
      return state + 1;
    });
  };

  const handlePreviousPage = () => {
    setIsLoading(true);
    setPage((state) => {
      if (state == 1) {
        return 1;
      }
      return state - 1;
    });
  };

  const handleOnSort = (headingIndex, direction) => {
    let currentSortDirection = "descending";
    if (sortDirection === "ascending") {
      currentSortDirection = "descending";
      setSortDirection("descending");
    } else {
      currentSortDirection = "ascending";
      setSortDirection("ascending");
    }

    let sortTag = "";
    if (headingIndex === 1) {
      sortTag = sortTag + "CREATED_AT_";
    } else if (headingIndex === 2) {
      sortTag = sortTag + "ORIGINATOR_";
    } else if (headingIndex === 3) {
      sortTag = sortTag + "AMOUNT_";
    }

    if (currentSortDirection === "ascending") {
      sortTag = sortTag + "ASC";
    } else {
      sortTag = sortTag + "DESC";
    }

    setSortValue(sortTag);
  };

  const paginationMarkup = (
    <Pagination
      hasPrevious={page != 1}
      onPrevious={handlePreviousPage}
      label={`Page ${page} of ${Math.ceil(contactData.length / limit)}`}
      hasNext={page < Math.ceil(contactData.length / limit)}
      onNext={handleNextPage}
    />
  );

  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    [],
  );

  const tabsMarkup = (
    <>
      <IndexFilters
        sortOptions={[]}
        sortSelected={() => {}}
        queryValue={queryValue}
        queryPlaceholder="Searching in contacts"
        onQueryChange={handleFiltersQueryChange}
        onQueryClear={() => setQueryValue('')}
        cancelAction={{
          onAction: () => setQueryValue(''),
          disabled: false,
          loading: false,
        }}
        onSort={() => {}}
        tabs={[]}
        selected={[]}
        onSelect={() => {}}
        filters={[]}
        appliedFilters={[]}
        onClearAll={() => setQueryValue('')}
        mode={mode}
        setMode={setMode}
      />

      <IndexTable
        resourceName={resourceName}
        itemCount={contactData.length}
        selectable={false}
        headings={[{ title: "Full Name" }, { title: "Email" }, { title: "Phone" }]}
        sortable={[false, false]}
        onSort={handleOnSort}
        sortDirection={sortDirection}
        loading={isLoading || queryIsLoading || queryIsFetching}
      >
        {rowMarkup}
      </IndexTable>

      <br />

      <LegacyStack distribution={"center"}>{paginationMarkup}</LegacyStack>

      <br />
    </>
  );

  return tabsMarkup;
};

ContactAdminList.propTypes = {
  itemLimit: PropTypes.number,
  onRowClick: PropTypes.func,
};

export default ContactAdminList;
