import React, { useState, useEffect, useCallback } from "react";

import {
  Card,
  Box,
  Button,
  LegacyStack,
  InlineGrid,
  BlockStack,
  Divider,
  Icon,
  Checkbox,
  Text,
} from "@shopify/polaris";
import { EditIcon } from "@shopify/polaris-icons";

import {
  useGetOpportunityQuery,
  useGetOpportunityCreditSubmissionQuery,
  useUpdateOpportunityMutation,
} from "../../services/api";

import {
  formatCurrency,
  formatNumericDate,
} from "../../utilities";

import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import DateTextField from "../../components/DateTextField";
import CardBoxTitle from "../../components/Card/BoxTitle";

const OpportunitiesReviewForm = (props) => {
  const { opportunityId, computeTransactions } = props;

  const {
    data: opportunity,
    isLoading: isLoadingOpportunity,
    refetch: refetchOpportunity,
    error: opportunityError,
  } = useGetOpportunityQuery(opportunityId);

  const {
    data: creditSubmission,
    isLoading: isLoadingCreditSubmission,
    refetch: refetchCreditSubmission,
  } = useGetOpportunityCreditSubmissionQuery(
    {
      id: opportunity.attributes.selected_credit_submission_id,
      opportunityId: opportunityId,
    },
    {
      skip: !opportunity.attributes.selected_credit_submission_id,
    }
  );

  const [updateOpportunity, { isLoading: isUpdatingOpportunity }] =
    useUpdateOpportunityMutation();

  const [isEditingValue, setIsEditingValue] = useState({
    install_date: false,
    booked_at: false,
    acceptance_date: false,
    commencement_date: false,
    closing_date: false,
    first_payment_date: false,
    last_payment_date: false,
    next_payment_date: false,
  });
  const [editedValue, setEditedValue] = useState({
    install_date: null,
    booked_at: null,
    acceptance_date: null,
    commencement_date: null,
    closing_date: null,
    pro_rata: false,
    first_payment_date: null,
    last_payment_date: null,
    next_payment_date: null
  });

  const toggleInputBox = (key) => {
    let editing = JSON.parse(JSON.stringify(isEditingValue));
    editing[key] = !editing[key];
    setIsEditingValue(editing);
  };

  const handleDateChange = (range, key) => {
    let values = JSON.parse(JSON.stringify(editedValue));
    values[key] = range;

    setEditedValue(values);
  };

  const handleEditProRata = (value) => {
    let values = JSON.parse(JSON.stringify(editedValue));
    values.pro_rata = value;

    setEditedValue(values);
    updateOpportunityWithNewValues(values);
  };

  const [grossYield, setGrossYield] = useState(0);

  const proRata = useCallback(() => {
    if (
      opportunity.attributes.commencement_date &&
      opportunity.attributes.acceptance_date
    ) {
      return (
        (opportunity.attributes.base_payment / 30) *
        Math.ceil(
          Math.abs(
            new Date(opportunity.attributes.commencement_date) -
              new Date(opportunity.attributes.acceptance_date)
          ) /
            (1000 * 60 * 60 * 24)
        )
      );
    } else {
      0;
    }
  }, [
    opportunity.attributes.acceptance_date,
    opportunity.attributes.base_payment,
    opportunity.attributes.commencement_date,
  ]);

  const totalGrossYield = useCallback(() => {
    return (
      grossYield -
      computeTransactions.vendor_points +
      proRata() +
      computeTransactions.progress_payment_interim_rent -
      computeTransactions.progress_payment_interest_cost
    );
  }, [
    computeTransactions.progress_payment_interest_cost,
    computeTransactions.progress_payment_interim_rent,
    computeTransactions.vendor_points,
    grossYield,
    proRata,
  ]);

  useEffect(() => {
    if (computeTransactions && opportunity) {

      setGrossYield(
        // computeTransactions.debt_present_value +
        // opportunity.attributes?.debt_present_value +
        //   computeTransactions.base_payments_value +
        //   opportunity.attributes?.total_fees -
        //   opportunity?.attributes?.total_transaction_expense -
        //   computeTransactions.doc_fee_lender -
        //   computeTransactions.total_paid_to_vendor +
        //   computeTransactions.vendor_admin_payables
        opportunity.attributes?.gross_yield
      );
    }
  }, [computeTransactions, opportunity]);

  useEffect(() => {
    let values = JSON.parse(JSON.stringify(editedValue));
    let keys = Object.keys(values);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (key === "pro_rata") {
        values.pro_rata = opportunity.attributes.pro_rata;
      } else {
        if (opportunity.attributes[key] != null) {
          values[key] = validDate(new Date(opportunity.attributes[key]));
        }
      }
    }

    setEditedValue(values);
  }, [opportunity]);

  const handleDateSave = (toggleKey) => {
    toggleInputBox(toggleKey);
    updateOpportunityWithNewValues();
  };

  const updateOpportunityWithNewValues = (newValue) => {
    let updateValue = newValue
      ? JSON.parse(JSON.stringify(newValue))
      : JSON.parse(JSON.stringify(editedValue));

    let form = {};
    for (let i = 0; i < Object.keys(updateValue).length; i++) {
      let key = Object.keys(updateValue)[i];
      if (updateValue[key] !== null) {
        form[key] = updateValue[key];
      }
    }

    return updateOpportunity({ id: opportunityId, ...form })
      .unwrap()
      .then(() => {
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validDate = (date) => {
    const dateObject = new Date(date);
    if (isFinite(dateObject)) {
      return dateObject;
    } else {
      return null;
    }
  };

  const reviewLayout = (
    <>
      <Card padding="0">
        <CardBoxTitle>
          <Text variant="headingMd" as="h6">Review</Text>
        </CardBoxTitle>
        <Box as="section" padding="400">
          <Box paddingBlockStart="400" paddingBlockEnd="400">
            <Text variant="headingMd" as="h6">Opportunity Details</Text>
          </Box>

          <BlockStack gap="800">
            <InlineGrid alignItems="start" gap="400" columns={5}>
              <Box padding="0">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">Rep/originator</Text>
                </Text>
                <p>{opportunity.attributes.originator_name || "-"}</p>
              </Box>
              <Box padding="0">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">Originator account</Text>
                </Text>
                <p>{opportunity.attributes.client_name || "-"}</p>
              </Box>
              <Box padding="0">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">Agreement #</Text>
                </Text>
                <p>{opportunity.attributes.payment_agreement_number || "-"}</p>
              </Box>
              <Box padding="0">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">Booked date</Text>
                </Text>
                {isEditingValue["booked_at"] ? (
                  <DatePicker
                    onChange={(value) => {
                      handleDateChange(value, "booked_at");
                    }}
                    placeholderText={"-"}
                    selected={validDate(editedValue["booked_at"] || new Date())}
                    customInput={
                      <DateTextField
                        inputName="booked_at"
                        onDateSave={handleDateSave}
                      />
                    }
                  />
                ) : (
                  <LegacyStack>
                    <LegacyStack.Item>
                      <div style={{ height: "20px" }}>
                        <p>
                          {opportunity.attributes.booked_at
                            ? formatNumericDate(
                                new Date(opportunity.attributes.booked_at)
                              )
                            : "-"}
                        </p>
                      </div>
                    </LegacyStack.Item>
                    <LegacyStack.Item>
                      <div style={{ height: "20px" }}>
                        <Button

                          size={"slim"}
                          onClick={() => {
                            toggleInputBox("booked_at");
                          }}
                          variant="plain">
                          <Icon tone={"interactive"} source={EditIcon} />
                        </Button>
                      </div>
                    </LegacyStack.Item>
                  </LegacyStack>
                )}
              </Box>
              <Box padding="0">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">Install date</Text>
                </Text>
                {isEditingValue["install_date"] ? (
                  <DatePicker
                    onChange={(value) => {
                      handleDateChange(value, "install_date");
                    }}
                    placeholderText={"-"}
                    selected={validDate(editedValue["install_date"] || new Date())}
                    customInput={
                      <DateTextField
                        inputName="install_date"
                        onDateSave={handleDateSave}
                      />
                    }
                  />
                ) : (
                  <LegacyStack>
                    <LegacyStack.Item>
                      <div style={{ height: "20px" }}>
                        <p>
                          {opportunity.attributes.install_date
                            ? formatNumericDate(
                                new Date(opportunity.attributes.install_date)
                              )
                            : "-"}
                        </p>
                      </div>
                    </LegacyStack.Item>
                    <LegacyStack.Item>
                      <div style={{ height: "20px" }}>
                        <Button

                          size={"slim"}
                          onClick={() => {
                            toggleInputBox("install_date");
                          }}
                          variant="plain">
                          <Icon tone={"interactive"} source={EditIcon} />
                        </Button>
                      </div>
                    </LegacyStack.Item>
                  </LegacyStack>
                )}
              </Box>
            </InlineGrid>

            <InlineGrid alignItems="start" gap="400" columns={5}>
              <Box padding="0">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">Total quote amount</Text>
                </Text>
                <p>
                  {formatCurrency(opportunity.attributes.total_finance_amount) ||
                    "-"}
                </p>
              </Box>

              <Box padding="0">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">Base payment</Text>
                </Text>
                <p>
                  {formatCurrency(opportunity.attributes.base_payment) || "-"}
                </p>
              </Box>

              <Box padding="0">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">Payments made to FORT</Text>
                </Text>
                <p>
                  { opportunity?.attributes?.total_base_payments_count > 0 ?
                      opportunity?.attributes?.total_base_payments_count : "-"
                  }
                </p>
              </Box>

              <Box padding="0">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">
                    Payments made to FORT ($)
                  </Text>
                </Text>
                <p>
                  { opportunity?.attributes?.total_base_payments_amount > 0 ?
                      formatCurrency(opportunity?.attributes?.total_base_payments_amount) : "-"
                  }
                </p>
              </Box>

              <Box padding="0">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">Doc fee paid</Text>
                </Text>
                <p>
                  { opportunity?.attributes?.documentation_fee_paid > 0 ?
                      formatCurrency(opportunity?.attributes?.documentation_fee_paid) : "-"
                  }
                </p>
              </Box>
            </InlineGrid>

            <InlineGrid alignItems="start" gap="400" columns={2}>
              <Box padding="0">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">
                    Origination fee from customer
                  </Text>
                </Text>
                <p>
                  { opportunity?.attributes?.origination_fee_from_customer > 0 ?
                      formatCurrency(opportunity?.attributes?.origination_fee_from_customer) :"-"
                  }
                </p>
              </Box>

              <Box padding="0">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">
                    Sum of remaining payments
                  </Text>
                </Text>
                <p>
                  { opportunity?.attributes?.sum_of_remaining_payments > 0 ?
                      formatCurrency(opportunity?.attributes?.sum_of_remaining_payments) : "-"
                  }
                </p>
              </Box>
            </InlineGrid>

            <InlineGrid alignItems="start" gap="400" columns={1}>
              <Box padding="0">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">
                    Total paid to vendor
                  </Text>
                </Text>
                <p>
                  {formatCurrency(computeTransactions.total_paid_to_vendor) ||
                    "-"}
                </p>
              </Box>
            </InlineGrid>
          </BlockStack>
        </Box>

        <Divider/>

        <Box padding="400">
          <Box paddingBlockEnd="400">
            <Text variant="headingMd" as="h6">Totals</Text>
          </Box>

          <InlineGrid alignItems="start" gap="400" columns={5}>
            <Box padding="0">
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Payments Total (Tax included)</Text>
              </Text>
              <p>
                {formatCurrency(opportunity.attributes.payments_total_including_tax) ||
                  "-"}
              </p>
            </Box>

            <Box padding="0">
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Total Sales Tax</Text>
              </Text>
              <p>
                {formatCurrency(opportunity.attributes.total_sales_tax) ||
                  "-"}
              </p>
            </Box>

            <Box padding="0">
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Total Finance Amount</Text>
              </Text>
              <p>
                {formatCurrency(opportunity.attributes.total_finance_amount) ||
                  "-"}
              </p>
            </Box>

            <Box padding="0">
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Total Months</Text>
              </Text>
              <p>
                {opportunity.attributes.total_months ||
                  "-"}
              </p>
            </Box>

            <Box padding="0">
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Total Final Invoice Amount</Text>
              </Text>
              <p>
                {formatCurrency(opportunity.attributes.total_final_invoice_amount) ||
                  "-"}
              </p>
            </Box>
          </InlineGrid>

          <br/>

          <InlineGrid alignItems="start" gap="400" columns={5}>
            <Box padding="0">
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Total Base Payments amount</Text>
              </Text>
              <p>
                {formatCurrency(opportunity.attributes.total_base_payments_amount) ||
                  "-"}
              </p>
            </Box>

            <Box padding="0">
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Total fees</Text>
              </Text>
              <p>
                {formatCurrency(opportunity.attributes.total_fees) ||
                  "-"}
              </p>
            </Box>

            <Box padding="0">
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Total Transaction Expense</Text>
              </Text>
              <p>
                {formatCurrency(opportunity.attributes.total_transaction_expense) ||
                  "-"}
              </p>
            </Box>
          </InlineGrid>
        </Box>

        <Divider/>

        <Box padding="400">
          <Box paddingBlockEnd="400">
            <Text variant="headingMd" as="h6">Pro rata</Text>
          </Box>

          <InlineGrid alignItems="start" gap="400" columns={3}>
            <Box padding="0">
              <Checkbox
                label="Collect pro rata"
                checked={editedValue["pro_rata"]}
                onChange={handleEditProRata}
              />
            </Box>

            <Box padding="0">
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Acceptance date</Text>
              </Text>
              {isEditingValue["acceptance_date"] ? (
                <DatePicker
                  onChange={(value) => {
                    handleDateChange(value, "acceptance_date");
                  }}
                  selected={validDate(editedValue["acceptance_date"] || new Date())}
                  customInput={
                    <DateTextField
                      inputName="acceptance_date"
                      onDateSave={handleDateSave}
                    />
                  }
                />
              ) : (
                <LegacyStack>
                  <LegacyStack.Item>
                    <div style={{ height: "20px" }}>
                      <p>
                        {opportunity.attributes.acceptance_date
                          ? formatNumericDate(
                              new Date(opportunity.attributes.acceptance_date)
                            )
                          : editedValue["acceptance_date"]
                          ? formatNumericDate(editedValue["acceptance_date"])
                          : "-"}
                      </p>
                    </div>
                  </LegacyStack.Item>
                  <LegacyStack.Item>
                    <div style={{ height: "20px" }}>
                      <Button

                        size={"slim"}
                        onClick={() => {
                          toggleInputBox("acceptance_date");
                        }}
                        variant="plain">
                        <Icon tone={"interactive"} source={EditIcon} />
                      </Button>
                    </div>
                  </LegacyStack.Item>
                </LegacyStack>
              )}
            </Box>

            <Box padding="0">
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Commencement date</Text>
              </Text>
              {isEditingValue["commencement_date"] ? (
                <DatePicker
                  onChange={(value) =>
                    handleDateChange(value, "commencement_date")
                  }
                  selected={validDate(editedValue["commencement_date"] || new Date())}
                  customInput={
                    <DateTextField
                      inputName="commencement_date"
                      onDateSave={handleDateSave}
                    />
                  }
                />
              ) : (
                <LegacyStack>
                  <LegacyStack.Item>
                    <div style={{ height: "20px" }}>
                      <p>
                        {opportunity.attributes.commencement_date
                          ? formatNumericDate(
                              new Date(opportunity.attributes.commencement_date)
                            )
                          : editedValue["commencement_date"]
                          ? formatNumericDate(editedValue["commencement_date"])
                          : "-"}
                      </p>
                    </div>
                  </LegacyStack.Item>
                  <LegacyStack.Item>
                    <div style={{ height: "20px" }}>
                      <Button

                        size={"slim"}
                        onClick={() => {
                          toggleInputBox("commencement_date");
                        }}
                        variant="plain">
                        <Icon tone={"interactive"} source={EditIcon} />
                      </Button>
                    </div>
                  </LegacyStack.Item>
                </LegacyStack>
              )}
            </Box>
          </InlineGrid>
        </Box>

        <Divider/>

        <Box padding="400">
          <Box paddingBlockEnd="400">
            <Text variant="headingMd" as="h6">Lender</Text>
          </Box>
          <LegacyStack
            alignment={"trailing"}
            distribution={"fillEvenly"}
            spacing={"loose"}
          >
            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Lender</Text>
              </Text>
              <p>
                {creditSubmission
                  ? creditSubmission.attributes.lender_name
                  : "-"}
              </p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Buy rate</Text>
              </Text>
              <p>
                {creditSubmission ? creditSubmission.attributes.buy_rate : "-"}
              </p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Closing date</Text>
              </Text>
              {isEditingValue["closing_date"] ? (
                <DatePicker
                  onChange={(value) => {
                    handleDateChange(value, "closing_date");
                  }}
                  selected={validDate(editedValue["closing_date"] || new Date())}
                  customInput={
                    <DateTextField
                      inputName="closing_date"
                      onDateSave={handleDateSave}
                    />
                  }
                />
              ) : (
                <LegacyStack>
                  <LegacyStack.Item>
                    <div style={{ height: "20px" }}>
                      <p>
                        {opportunity.attributes.closing_date
                          ? formatNumericDate(
                              new Date(opportunity.attributes.closing_date)
                            )
                          : editedValue["closing_date"]
                          ? formatNumericDate(editedValue["closing_date"])
                          : "-"}
                      </p>
                    </div>
                  </LegacyStack.Item>
                  <LegacyStack.Item>
                    <div style={{ height: "20px" }}>
                      <Button

                        size={"slim"}
                        onClick={() => {
                          toggleInputBox("closing_date");
                        }}
                        variant="plain">
                        <Icon tone={"interactive"} source={EditIcon} />
                      </Button>
                    </div>
                  </LegacyStack.Item>
                </LegacyStack>
              )}
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Debt PV</Text>
              </Text>
              <p>
                { opportunity?.attributes?.debt_present_value > 0 ?
                    formatCurrency(opportunity?.attributes?.debt_present_value) : "-"
                }
              </p>
            </LegacyStack.Item>
          </LegacyStack>

          <br />
          <br />

          <LegacyStack
            alignment={"trailing"}
            distribution={"fillEvenly"}
            spacing={"loose"}
          >
            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Lender fee</Text>
              </Text>
              <p>{"-"}</p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">
                  Origination fee from lender
                </Text>
              </Text>
              <p>
                {formatCurrency(computeTransactions.origination_fee_lender) ||
                  "-"}
              </p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">
                  Net Debt PV
                </Text>
              </Text>
              <p>
                {formatCurrency(computeTransactions.net_debt_pv) ||
                  "-"}
              </p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">
                  Debt PV Base Payment
                </Text>
              </Text>
              <p>
                {formatCurrency(computeTransactions.debt_pv_base_payment) ||
                  "-"}
              </p>
            </LegacyStack.Item>
          </LegacyStack>

          <br />

          <LegacyStack
            alignment={"trailing"}
            distribution={"fillEvenly"}
            spacing={"loose"}
          >
            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">First payment date</Text>
              </Text>
              {isEditingValue["first_payment_date"] ? (
                <DatePicker
                  onChange={(value) => {
                    handleDateChange(value, "first_payment_date");
                  }}
                  selected={validDate(editedValue["first_payment_date"] || new Date())}
                  customInput={
                    <DateTextField
                      inputName="first_payment_date"
                      onDateSave={handleDateSave}
                    />
                  }
                />
              ) : (
                <LegacyStack>
                  <LegacyStack.Item>
                    <div style={{ height: "20px" }}>
                      <p>
                        {opportunity.attributes.first_payment_date
                          ? formatNumericDate(
                              new Date(opportunity.attributes.first_payment_date)
                            )
                          : editedValue["first_payment_date"]
                          ? formatNumericDate(editedValue["first_payment_date"])
                          : "-"}
                      </p>
                    </div>
                  </LegacyStack.Item>
                  <LegacyStack.Item>
                    <div style={{ height: "20px" }}>
                      <Button

                        size={"slim"}
                        onClick={() => {
                          toggleInputBox("first_payment_date");
                        }}
                        variant="plain">
                        <Icon tone={"interactive"} source={EditIcon} />
                      </Button>
                    </div>
                  </LegacyStack.Item>
                </LegacyStack>
              )}
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Last payment date</Text>
              </Text>
              {isEditingValue["last_payment_date"] ? (
                <DatePicker
                  onChange={(value) => {
                    handleDateChange(value, "last_payment_date");
                  }}
                  selected={validDate(editedValue["last_payment_date"] || new Date())}
                  customInput={
                    <DateTextField
                      inputName="last_payment_date"
                      onDateSave={handleDateSave}
                    />
                  }
                />
              ) : (
                <LegacyStack>
                  <LegacyStack.Item>
                    <div style={{ height: "20px" }}>
                      <p>
                        {opportunity.attributes.last_payment_date
                          ? formatNumericDate(
                              new Date(opportunity.attributes.last_payment_date)
                            )
                          : editedValue["last_payment_date"]
                          ? formatNumericDate(editedValue["last_payment_date"])
                          : "-"}
                      </p>
                    </div>
                  </LegacyStack.Item>
                  <LegacyStack.Item>
                    <div style={{ height: "20px" }}>
                      <Button

                        size={"slim"}
                        onClick={() => {
                          toggleInputBox("last_payment_date");
                        }}
                        variant="plain">
                        <Icon tone={"interactive"} source={EditIcon} />
                      </Button>
                    </div>
                  </LegacyStack.Item>
                </LegacyStack>
              )}
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Next payment date</Text>
              </Text>
              {isEditingValue["next_payment_date"] ? (
                <DatePicker
                  onChange={(value) => {
                    handleDateChange(value, "next_payment_date");
                  }}
                  selected={validDate(editedValue["next_payment_date"] || new Date())}
                  customInput={
                    <DateTextField
                      inputName="next_payment_date"
                      onDateSave={handleDateSave}
                    />
                  }
                />
              ) : (
                <LegacyStack>
                  <LegacyStack.Item>
                    <div style={{ height: "20px" }}>
                      <p>
                        {opportunity.attributes.next_payment_date
                          ? formatNumericDate(
                              new Date(opportunity.attributes.next_payment_date)
                            )
                          : editedValue["next_payment_date"]
                          ? formatNumericDate(editedValue["next_payment_date"])
                          : "-"}
                      </p>
                    </div>
                  </LegacyStack.Item>
                  <LegacyStack.Item>
                    <div style={{ height: "20px" }}>
                      <Button

                        size={"slim"}
                        onClick={() => {
                          toggleInputBox("next_payment_date");
                        }}
                        variant="plain">
                        <Icon tone={"interactive"} source={EditIcon} />
                      </Button>
                    </div>
                  </LegacyStack.Item>
                </LegacyStack>
              )}
            </LegacyStack.Item>
          </LegacyStack>
        </Box>

        <Divider/>

        <Box padding="400">
          <Box paddingBlockEnd="400">
            <Text variant="headingMd" as="h6">Expenses</Text>
          </Box>
          <LegacyStack
            alignment={"trailing"}
            distribution={"fillEvenly"}
            spacing={"loose"}
          >
            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">
                  FORT Transaction expense
                </Text>
              </Text>
              <p>
                { opportunity?.attributes?.total_transaction_expense > 0 ?
                    formatCurrency(opportunity?.attributes?.total_transaction_expense) : "-"
                }
              </p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">DOC Fee to Lender</Text>
              </Text>
              <p>{formatCurrency(computeTransactions.doc_fee_lender) || "-"}</p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Other fee to lender</Text>
              </Text>
              <p>
                {formatCurrency(computeTransactions.other_fee_lender) || "-"}
              </p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Total deal fees</Text>
              </Text>
              <p>
                { opportunity?.attributes?.total_fees > 0 ?
                    formatCurrency(opportunity?.attributes?.total_fees) : "-"
                }
              </p>
            </LegacyStack.Item>
          </LegacyStack>

          <br />
          <br />

          <LegacyStack
            alignment={"trailing"}
            distribution={"fillEvenly"}
            spacing={"loose"}
          >
            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">
                  Progress payment interim rent
                </Text>
              </Text>
              <p>
                {formatCurrency(
                  computeTransactions.progress_payment_interim_rent
                ) || "-"}
              </p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">
                  Progress payment interest
                </Text>
              </Text>
              <p>
                {formatCurrency(
                  computeTransactions.progress_payment_interest_cost
                ) || "-"}
              </p>
            </LegacyStack.Item>
          </LegacyStack>
        </Box>

        <Divider/>

        <Box padding="400">
          <Box paddingBlockEnd="400">
            <Text variant="headingMd" as="h6">Summary Economics</Text>
          </Box>
          <LegacyStack
            alignment={"trailing"}
            distribution={"fillEvenly"}
            spacing={"loose"}
          >
            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Amount due to FORT</Text>
              </Text>
              <p>
                { opportunity?.attributes?.debt_present_value ?
                  formatCurrency(
                    opportunity?.attributes?.debt_present_value -
                    computeTransactions.total_paid_to_vendor
                  ) : "-"
                }
              </p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Gross yield</Text>
              </Text>
              <p>{formatCurrency(grossYield) || "-"}</p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Vendor referral fee</Text>
              </Text>
              <p>{formatCurrency(computeTransactions.vendor_points) || "-"}</p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">
                  Commission eligible yield
                </Text>
              </Text>
              <p>
                {formatCurrency(
                  grossYield - computeTransactions.vendor_points
                ) || "-"}
              </p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="span" tone="subdued">Pro rata</Text>
              <p>
                {opportunity.attributes.commencement_date &&
                opportunity.attributes.acceptance_date &&
                (opportunity.attributes.pro_rata || editedValue["pro_rata"])
                  ? formatCurrency(proRata())
                  : "-"}
              </p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Subsidy</Text>
              </Text>
              <p>{formatCurrency(computeTransactions.subsidy) || "-"}</p>
            </LegacyStack.Item>
          </LegacyStack>

          <br />
          <br />

          <LegacyStack
            alignment={"trailing"}
            distribution={"fillEvenly"}
            spacing={"loose"}
          >
            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Total gross yield</Text>
              </Text>
              <p>{formatCurrency(totalGrossYield()) || "-"}</p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Gross yield %</Text>
              </Text>

              <p>
                { computeTransactions.total_paid_to_vendor > 0 ?
                  Math.round(
                    (grossYield / computeTransactions.total_paid_to_vendor) *
                      100 *
                      100
                  ) / 100 || "-"
                  :
                  "--"
                }
                %
              </p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">
                  Associate Referral Fee
                </Text>
              </Text>
              <p>
                {formatCurrency(computeTransactions.associate_referral_fee) ||
                  "-"}
              </p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Referral %</Text>
              </Text>
              <p>
                { grossYield > 0 ?
                  Math.round(
                    (computeTransactions.associate_referral_fee / grossYield) *
                      100 *
                      100
                  ) / 100 || "-"
                  :
                  "--"
                }
                %
              </p>
            </LegacyStack.Item>
          </LegacyStack>

          <br />
          <br />

          <LegacyStack
            alignment={"trailing"}
            distribution={"fillEvenly"}
            spacing={"loose"}
          >
            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">Net yield after fees</Text>
              </Text>
              <p>
                {formatCurrency(
                  grossYield - computeTransactions.associate_referral_fee
                ) || "-"}
              </p>
            </LegacyStack.Item>

            <LegacyStack.Item>
              <Text variant="bodySm" as="p">
                <Text as="span" tone="subdued">
                  Net yield after fees (%)
                </Text>
              </Text>
              <p>
                { computeTransactions.total_paid_to_vendor > 0 ?
                  Math.round(
                    (((grossYield - computeTransactions.associate_referral_fee) /
                      computeTransactions.total_paid_to_vendor) *
                      100 *
                      100) /
                      100
                  ) || "-"
                  :
                  "--"
                }
                %
              </p>
            </LegacyStack.Item>
          </LegacyStack>
        </Box>
      </Card>
    </>
  );

  return reviewLayout;
};
OpportunitiesReviewForm.propTypes = {
  computeTransactions: PropTypes.object,
  opportunityId: PropTypes.string,
};

export default OpportunitiesReviewForm;
