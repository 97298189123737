import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  Page,
  Layout,
  IndexTable,
  Badge,
  Truncate,
  InlineStack,
  BlockStack,
  ButtonGroup,
  Button,
  Text,
  Box,
} from "@shopify/polaris";

import { formatNumericDate, formatCurrency } from "../utilities";

import { useGetOpportunitiesQuery } from "../services/api";

import LoadingScreen from "../components/Auth/Loading";
import CardBoxTitle from "../components/Card/BoxTitle";

import { CurrentContactContext } from "../contexts/Contact";
import HubspotForm from "../components/HubspotForm";
import { ExternalIcon } from "@shopify/polaris-icons";

const Customer = () => {
  const history = useHistory();

  const { currentContact, isLoadingPermissions, refetchCurrentContact } =
    useContext(CurrentContactContext);

  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [disambiguatedFilters, setDisambiguatedFilters] = useState(null);
  const [sortValue, setSortValue] = useState("UPDATED_AT_DESC");
  const [queryValue, setQueryValue] = useState(undefined);

  useEffect(() => {
    if (currentContact) {
      if (isLoadingPermissions) return;

      if (!currentContact.attributes.has_complete_profile) {
        refetchCurrentContact()
          .unwrap()
          .then((fetchedContact) => {
            if (!fetchedContact.attributes.has_complete_profile) {
              history.push(`/profile`);
            }
          });
      }
    }
  }, [
    currentContact,
    currentContact?.attributes?.has_complete_profile,
    history,
    isLoadingPermissions,
    refetchCurrentContact,
  ]);

  const navigateToOpportunityShow = (id) => {
    history.push(`/opportunities/${id}/share`);
  };

  const {
    data: opportunityData = {},
    isLoading,
    isFetching,
  } = useGetOpportunitiesQuery(
    {
      clientAccountId: "",
      page: page,
      limit: limit,
      filters: disambiguatedFilters,
      sort: sortValue,
      search: queryValue,
      expand: true,
    },
    { skip: !currentContact || isLoadingPermissions }
  );

  const [customerOpportunities, setCustomerOpportunities] = useState([]);

  useEffect(() => {
    if (opportunityData?.opportunities?.data.length > 0) {
      const oppList = opportunityData?.opportunities?.data?.map((item) => item);
      setCustomerOpportunities(oppList);
    }
  }, [opportunityData]);

  const rowMarkup = customerOpportunities.map((item, index) => {
    console.log(item);
    return (
      <IndexTable.Row
        id={index}
        key={index}
        position={index}
        onNavigation={() => navigateToOpportunityShow(item.id)}
      >
        <IndexTable.Cell>
          <Text variant="bodyMd" as="span" fontWeight="semibold">
            <Truncate>
              <span data-primary-link>
                {item.attributes.primary_vendor_name}
              </span>
            </Truncate>
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {item.attributes.commencement_date
            ? formatNumericDate(new Date(item.attributes.commencement_date))
            : "--"}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {formatCurrency(item.attributes.total_finance_amount)}
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  const dealTable = (
    <IndexTable
      resourceName={{
        singular: "opportunity",
        plural: "opportunities",
      }}
      selectable={false}
      itemCount={customerOpportunities.length}
      headings={[
        { title: "Vendor" },
        { title: "Commencement date" },
        { title: "Amount" },
      ]}
      loading={isLoading || isFetching}
    >
      {rowMarkup}
    </IndexTable>
  );

  const [showForm, setShowForm] = useState(false);
  const toggleShowForm = () => setShowForm(!showForm);

  const pageMarkup = (
    <Page title={`Welcome back, ${currentContact?.attributes?.first_name}`}>
      <Layout>
        <Layout.Section>
          <Card padding={"0"}>
            <Box padding={"400"}>
              <Text variant="headingMd" as="h2">
                Your deals
              </Text>
            </Box>

            {dealTable}
          </Card>
        </Layout.Section>

        <Layout.Section>
          <Card padding="0">
            <CardBoxTitle>
              <Text variant="headingMd" as="h6">
                Making more purchase?
              </Text>
            </CardBoxTitle>
            {!showForm ? (
              <Box padding="400">
                <BlockStack gap="400">
                  <p>
                    Meet with your FORT representative for a free credit consult
                    and get pre-qualified for up to $500k in equipment financing
                  </p>
                  <InlineStack>
                    <ButtonGroup>
                      <Button
                        accessibilityLabel="Add a new financing request (opens a form)"
                        onClick={toggleShowForm}
                        variant="primary"
                      >
                        Add a new request
                      </Button>

                      {process.env.CALENDLY_LINK && (
                        <Button
                          accessibilityLabel="Talk to a FORT representative (opens a new window)"
                          icon={ExternalIcon}
                          url={process.env.CALENDLY_LINK}
                          external
                          variant="plain"
                        >
                          Talk to a FORT representative
                        </Button>
                      )}
                    </ButtonGroup>
                  </InlineStack>
                </BlockStack>
              </Box>
            ) : (
              <Box padding="400">
                <HubspotForm formId="180e7b0d-cda1-4a43-a293-3b9f832807ae" />
              </Box>
            )}
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );

  return currentContact ? pageMarkup : <LoadingScreen />;
};

export default Customer;
