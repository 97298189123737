import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";

import {
  Bleed,
  Card,
  Box,
  Divider,
  InlineStack,
  BlockStack,
  Banner,
  Link,
  Icon,
  EmptyState,
  DropZone,
  Button,
  ButtonGroup,
  OptionList,
  Modal,
  Checkbox,
  TextField,
  Spinner,
  Thumbnail,
  Layout,
  Autocomplete,
  Truncate,
  Text,
  FormLayout,
  Popover,
  ActionList,
  InlineGrid,
} from "@shopify/polaris";

import {
  AttachmentIcon,
  PlusCircleIcon,
  CheckCircleIcon,
  NoteIcon,
  ExternalIcon,
  EmailIcon,
  ViewIcon,
  OrderFulfilledIcon,
  SearchIcon,
  DeleteIcon,
  MenuHorizontalIcon,
  ClipboardIcon,
} from "@shopify/polaris-icons";

import {
  useGetOpportunityAttachmentsQuery,
  useUpdateOpportunityAttachmentMutation,
  useCreateOpportunityAttachmentsMutation,
  usePurgeOpportunityAttachmentMutation,
  useDeleteOpportunityAttachmentMutation,
  useCreateDocusignEmbeddedSendingMutation,
  useLazyGetDocusignRegeneratedEmbeddedSendingUrlQuery,
  useUpdateOpportunityMutation,
  useGetOpportunityQuery,
  useGetGuarantorsQuery,
  useSearchGuarantorsQuery,
  useCreateGuarantorMutation,
  useGetOpportunityCreditSubmissionQuery,
  useGetDocusignEnvelopesQuery,
  useDeleteDocusignEnvelopeMutation,
  useGetOpportunityDocumentsQuery,
  useCreateOpportunityEventMutation,
} from "../../services/api";

import { CurrentContactContext } from "../../contexts/Contact";
import { useToast } from "../../contexts/Toast";

import { useDebounce } from "use-debounce";
import { DirectUploadProvider } from "react-activestorage-provider";

import CardBoxTitle from "../../components/Card/BoxTitle";
import GuarantorList from "../../components/Opportunity/GuarantorList";

import {
  formatNumericDate,
  formatShortDateTime,
  copyTextToClipboard,
} from "../../utilities";
import emptySearchCompanies from "../../assets/emptySearchCompanies.svg";

const OpportunitiesFundingChecklist = () => {
  const { id: opportunityId } = useParams();

  const { currentContact } = useContext(CurrentContactContext);

  const { showToastWithMessage } = useToast();

  const { data: opportunity } = useGetOpportunityQuery(opportunityId);

  const [updateOpportunity, { isLoading: isUpdatingOpportunity }] =
    useUpdateOpportunityMutation();

  const {
    data: opportunityAttachments,
    refetch: refetchOpportunityAttachments,
  } = useGetOpportunityAttachmentsQuery(
    { opportunityId: opportunityId, isPublic: false },
    { skip: !opportunityId }
  );

  const {
    data: creditSubmission,
    isLoading: isLoadingCreditSubmission,
    refetch: refetchCreditSubmission,
  } = useGetOpportunityCreditSubmissionQuery(
    {
      id: opportunity.attributes.selected_credit_submission_id,
      opportunityId: opportunityId,
    },
    {
      skip: !opportunity.attributes.selected_credit_submission_id,
    }
  );

  const {
    data: guarantors = [],
    isLoading: isLoadingGuarantors = false,
    refetch: refetchGuarantors,
  } = useGetGuarantorsQuery(opportunityId, { skip: !opportunityId });

  const {
    data: docusignEnvelopes = [],
    isLoading: isDocusignEnvelopesLoading = false,
    refetch: refetchDocusignEnvelopes,
  } = useGetDocusignEnvelopesQuery(opportunityId, { skip: !opportunityId });

  const [deleteDocusignEnvelope, { isLoading: isDeletingDocusignEnvelope }] =
    useDeleteDocusignEnvelopeMutation();

  const [isEnvelopeBeingDeleted, setIsEnvelopeBeingDeleted] = useState({});
  const [isUrlBeingRegenerated, setIsUrlBeingRegenerated] = useState({});

  useEffect(() => {
    if (docusignEnvelopes.length > 0) {
      let isDeleting = {};
      let urls = {};
      for (let i = 0; i < docusignEnvelopes.length; i++) {
        isDeleting[docusignEnvelopes[i].id] = false;
        urls[docusignEnvelopes[i].id] = false;
      }

      setIsEnvelopeBeingDeleted(isDeleting);
      setIsUrlBeingRegenerated(urls);
    }
  }, [docusignEnvelopes]);

  const [documentGuarantors, setDocumentGuarantors] = useState([]);
  const [selectedDocumentGuarantors, setSelectedDocumentGuarantors] = useState(
    []
  );

  useEffect(() => {
    if (guarantors) {
      let gSelect = [];

      for (let i = 0; i < guarantors.length; i++) {
        gSelect.push({
          value: guarantors[i],
          label: guarantors[i].attributes.name,
        });
      }

      setDocumentGuarantors(gSelect);
    }
  }, [guarantors]);

  const {
    data: documents,
    isLoading: isDocumentsLoading = false,
    refetch: refetchDocuments,
  } = useGetOpportunityDocumentsQuery(opportunityId, { skip: !opportunityId });

  const selectedLenderCard = (
    <Card padding="0">
      <CardBoxTitle>
        <Text variant="headingMd" as="h6">
          Selected lender
        </Text>
      </CardBoxTitle>
      <Box as="section" padding="400">
        {opportunity.attributes.selected_credit_submission_id == null && (
          <>
            <Banner tone="info">
              Lender information will be complete once an approved client
              submission is selected.
              <br />
              <Link>Learn more about managing credit submissions</Link>
            </Banner>
            <br />
          </>
        )}

        <InlineStack gap="600">
          <div>
            <Text variant="bodySm" as="p">
              <Text as="span" tone="subdued">
                Lender name
              </Text>
            </Text>
            <p>
              {creditSubmission
                ? creditSubmission.attributes.lender_name
                : "--"}
            </p>
          </div>

          <div>
            <Text variant="bodySm" as="p">
              <Text as="span" tone="subdued">
                Buy rate
              </Text>
            </Text>
            <p>
              {creditSubmission ? creditSubmission.attributes.buy_rate : "--"}
            </p>
          </div>

          <div>
            <Text variant="bodySm" as="p">
              <Text as="span" tone="subdued">
                Bank PV Amount
              </Text>
            </Text>
            <p>
              {creditSubmission
                ? creditSubmission.attributes.approved_amount
                : "--"}
            </p>
          </div>
        </InlineStack>

        {creditSubmission?.attributes.notes && (
          <>
            <br />

            <InlineStack>
              <div>
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">
                    Notes
                  </Text>
                </Text>
                <p>
                  {creditSubmission ? creditSubmission.attributes.notes : "--"}
                </p>
              </div>
            </InlineStack>
          </>
        )}
      </Box>
    </Card>
  );

  const [generateDocsModalOpen, setGenerateDocsModalOpen] = useState(false);
  const [documentOptionList, setDocumentOptionList] = useState([]);
  const toggleGenerateDocsModalOpen = () => {
    setDocumentInput("");
    setDocumentsToSend([]);
    setCountersignatureRequired(true);
    setGenerateDocsModalOpen(!generateDocsModalOpen);
  };

  useEffect(() => {
    if (documents) {
      let pdfOptionList = [];

      //const excludedDocuments = ["Guarantee - Individual", "Cross Corp Guarantee"];
      const excludedDocuments = [];

      for (let i = 0; i < documents.length; i++) {
        if (!excludedDocuments.includes(documents[i].attributes.name)) {
          pdfOptionList.push({
            value: documents[i],
            label: documents[i].attributes.name,
          });
        }
      }

      setDocumentOptionList(pdfOptionList);
    }
  }, [documents, isDocumentsLoading]);

  const [documentsToSend, setDocumentsToSend] = useState([]);

  const [
    createDocusignEmbeddedSending,
    { isLoading: isCreatingDocusignEmbeddedSending },
  ] = useCreateDocusignEmbeddedSendingMutation();

  const [docusignRedirectUrl, setDocusignRedirectUrl] = useState({});

  const [
    getLazyDocusignRegeneratedEmbeddedSendingUrl,
    { isLoading: isLoadingRegeneratedEmbeddedSigningUrl },
    lastPromiseInfo,
  ] = useLazyGetDocusignRegeneratedEmbeddedSendingUrlQuery();

  const fetchEmbeddedSendingUrl = (envelopeId) => {
    let urls = JSON.parse(JSON.stringify(isUrlBeingRegenerated));
    urls[envelopeId] = true;
    setIsUrlBeingRegenerated(urls);

    return getLazyDocusignRegeneratedEmbeddedSendingUrl({
      envelopeId: envelopeId,
      opportunityId: opportunityId,
    })
      .unwrap()
      .then(({ redirect_url }) => {
        let urls = JSON.parse(JSON.stringify(isUrlBeingRegenerated));
        urls[envelopeId] = false;
        setIsUrlBeingRegenerated(urls);

        if (redirect_url) {
          let urls = JSON.parse(JSON.stringify(docusignRedirectUrl));
          urls[envelopeId] = redirect_url;

          setDocusignRedirectUrl(urls);
          window.open(redirect_url, "_blank");
        }

        return { status: "success" };
      })
      .catch();
  };

  const createEmbeddedSigningLink = () => {
    setDocusignRedirectUrl({});
    toggleGenerateDocsModalOpen();

    let body = {
      documents: documentsToSend,
      guarantors: selectedDocumentGuarantors,
      countersignature_required: countersignatureRequired,
    };

    return createDocusignEmbeddedSending({
      opportunityId: opportunityId,
      ...body,
    }).then(() => {
      refetchDocusignEnvelopes();

      return { status: "success" };
    });
  };

  const [countersignatureRequired, setCountersignatureRequired] =
    useState(true);

  const generateDocsModal = (
    <Modal
      open={generateDocsModalOpen}
      onClose={toggleGenerateDocsModalOpen}
      title="Create envelope"
      primaryAction={{
        content: "Create envelope",
        onAction: createEmbeddedSigningLink,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: toggleGenerateDocsModalOpen,
        },
      ]}
    >
      <Modal.Section flush>
        <FormLayout>
          <OptionList
            title={"Documents"}
            onChange={setDocumentsToSend}
            options={documentOptionList}
            selected={documentsToSend}
            allowMultiple
          />
        </FormLayout>
      </Modal.Section>

      {documentGuarantors.length > 0 && (
        <Modal.Section flush>
          <FormLayout>
            <OptionList
              title={"Guarantors"}
              onChange={setSelectedDocumentGuarantors}
              options={documentGuarantors}
              selected={selectedDocumentGuarantors}
              allowMultiple
            />
          </FormLayout>
        </Modal.Section>
      )}

      <Modal.Section>
        <FormLayout>
          <Checkbox
            label="Require countersignature"
            checked={countersignatureRequired}
            onChange={setCountersignatureRequired}
            helpText="If checked, the document package will require a countersignature from the admin."
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  const handleRemoveEnvelope = (envelopeId) => {
    let willDelete = confirm(
      "Are you sure you want to remove the document package?"
    );
    if (willDelete) {
      // Set deleting to true
      let isDeleting = JSON.parse(JSON.stringify(isEnvelopeBeingDeleted));
      isDeleting[envelopeId] = true;
      setIsEnvelopeBeingDeleted(isDeleting);

      return deleteDocusignEnvelope({
        id: envelopeId,
        opportunityId: opportunityId,
      })
        .unwrap()
        .then(() => {
          refetchDocusignEnvelopes();
          return { status: "success" };
        })
        .catch();
    }
  };

  const docusignEnvelopeTimestamps = (envelope) => {
    return [
      ...(envelope.attributes.docusign_envelope_sent_at
        ? [
            {
              icon: EmailIcon,
              description: formatShortDateTime(
                new Date(envelope.attributes.docusign_envelope_sent_at)
              ),
              title: "Sent",
            },
          ]
        : []),
      ...(envelope.attributes.docusign_envelope_delivered_at
        ? [
            {
              icon: ViewIcon,
              description: formatShortDateTime(
                new Date(envelope.attributes.docusign_envelope_delivered_at)
              ),
              title: "Viewed",
            },
          ]
        : []),
      ...(envelope.attributes.docusign_envelope_completed_at
        ? [
            {
              icon: OrderFulfilledIcon,
              description: formatShortDateTime(
                new Date(envelope.attributes.docusign_envelope_completed_at)
              ),
              title: "Signed",
            },
          ]
        : []),
    ];
  };

  const docusignRootUrl =
    process.env.APPLICATION_HOST === "app.fortifypay.com"
      ? "app.docusign.com"
      : "appdemo.docusign.com";

  const handleViewOrEditEnvelope = (envelope) => {
    let envelopeIsDraft = !(
      envelope.attributes.docusign_envelope_sent_at ||
      envelope.attributes.docusign_envelope_delivered_at ||
      envelope.attributes.docusign_envelope_completed_at
    );

    if (envelopeIsDraft) {
      fetchEmbeddedSendingUrl(envelope.id);
    } else {
      const documentPackageUrl = `https://${docusignRootUrl}/documents/details/${envelope.attributes.docusign_envelope_id}`;
      window.open(documentPackageUrl, "_blank");
    }
  };

  const docusignEnvelopeCard = (envelope) => {
    let envelopeId = envelope.id;
    let draft = !(
      envelope.attributes.docusign_envelope_sent_at ||
      envelope.attributes.docusign_envelope_delivered_at ||
      envelope.attributes.docusign_envelope_completed_at
    );

    const { created_at } = envelope.attributes;

    return (
      <Box padding="400">
        <InlineStack align="space-between" blockAlign="center">
          <InlineStack gap="200">
            <Thumbnail source={NoteIcon} size="small" alt="Document package" />

            <BlockStack gap="100">
              <Truncate>
                {`Document Package - ${formatNumericDate(
                  new Date(created_at)
                )}`}
              </Truncate>
              <Text variant="bodySm" as="p">
                Envelope ID: {envelope.attributes.docusign_envelope_id}
              </Text>
              <Text variant="bodySm" as="p">
                Documents: {envelope.attributes.document_list.join(", ")}
              </Text>

              <BlockStack spacing="tight">
                {docusignEnvelopeTimestamps(envelope).map(
                  (timestamp, index) => {
                    return (
                      <InlineStack
                        key={`timestamp-${index}`}
                        blockAlign="center"
                        gap="200"
                      >
                        <Icon source={timestamp.icon} tone="base" />
                        <p>
                          {timestamp.title} - {timestamp.description}
                        </p>
                      </InlineStack>
                    );
                  }
                )}
              </BlockStack>
            </BlockStack>
          </InlineStack>

          <Button
            onClick={() => {
              handleViewOrEditEnvelope(envelope);
            }}
            loading={isUrlBeingRegenerated[envelopeId]}
            icon={ExternalIcon}
            external={!draft}
            variant="plain"
          >
            {envelope.attributes.docusign_envelope_sent_at
              ? "View package"
              : "View/edit package"}
          </Button>

          <Button
            onClick={() => {
              handleRemoveEnvelope(envelopeId);
            }}
            loading={isEnvelopeBeingDeleted[envelopeId]}
            variant="plain"
            tone="critical"
          >
            Remove package
          </Button>
        </InlineStack>
      </Box>
    );
  };

  const handlePreviewDocs = () => {
    const slug = opportunity.attributes.document_package_vendor_slug;
    const pathname = `/document_tests/${slug}/${opportunityId}/`;

    window.open(pathname, "_blank");
  };

  const docusignEnvelopeEmptyState =
    isCreatingDocusignEmbeddedSending || isDocusignEnvelopesLoading ? (
      <div style={{ position: "relative", width: "100%", height: "48px" }}>
        <div style={{ position: "absolute", left: "calc(50% - 24px)" }}>
          <Spinner accessibilityLabel="Loading envelopes" size="large" />
        </div>
      </div>
    ) : (
      docusignEnvelopes.length == 0 && (
        <Box padding={"400"}>
          {!opportunity.attributes.has_accepted_financing_option && (
            <Banner tone="info">
              The doc package will be available once customer selects an
              approved financing option
              <br />
              <Link>
                Learn more about how customers select a financing option
              </Link>
            </Banner>
          )}

          {isCreatingDocusignEmbeddedSending || isDocusignEnvelopesLoading ? (
            <Spinner
              accessibilityLabel="Loading docusign envelope"
              size="large"
            />
          ) : (
            <EmptyState
              heading={"Create an envelope in DocuSign"}
              secondaryAction={{
                content: "Preview",
                onClick: handlePreviewDocs,
                // disabled: true,
              }}
              action={{
                content: "Create envelope",
                onClick: toggleGenerateDocsModalOpen,
              }}
              image={emptySearchCompanies}
            >
              <p>
                The envelope will not be sent immediately. It will appear in the
                customer portal, but the package can be edited before sending.
              </p>
            </EmptyState>
          )}
        </Box>
      )
    );

  const documentPackage = (
    <Card padding="0">
      <CardBoxTitle>
        <Text variant="headingMd" as="h6">
          DocuSign envelopes
        </Text>
      </CardBoxTitle>
      {docusignEnvelopeEmptyState}
      {docusignEnvelopes.map((envelope) => {
        return docusignEnvelopeCard(envelope);
      })}

      <Box as="section" background="bg-surface-secondary" padding="400">
        <InlineStack align="space-between">
          <div>
            <Text variant="bodySm" as="p">
              <Text as="span" tone="subdued">
                End option
              </Text>
            </Text>
            <p>--</p>
          </div>

          <div>
            <Text variant="bodySm" as="p">
              <Text as="span" tone="subdued">
                Accepted terms
              </Text>
            </Text>
            <p>--</p>
          </div>

          <div>
            <Text variant="bodySm" as="p">
              <Text as="span" tone="subdued">
                Sell rate
              </Text>
            </Text>
            <p>--</p>
          </div>

          <div>
            <Text variant="bodySm" as="p">
              <Text as="span" tone="subdued">
                Opportunity PV
              </Text>
            </Text>
            <p>--</p>
          </div>

          <div>
            <Text variant="bodySm" as="p">
              <Text as="span" tone="subdued">
                Fort buy rate
              </Text>
            </Text>
            <p>--</p>
          </div>
        </InlineStack>
      </Box>
      <Divider />
      <Box as="section" padding="400">
        <InlineStack align="end" gap="200">
          <Button onClick={handlePreviewDocs}>Preview docs</Button>
          {docusignEnvelopes.length > 0 ? (
            <Button variant="primary" onClick={toggleGenerateDocsModalOpen}>
              Generate doc package
            </Button>
          ) : null}
        </InlineStack>
      </Box>
    </Card>
  );

  const guarantorsEmptyStateSideCard = (
    <Box padding="400">
      <Text variant="headingLg" as="h5" fontWeight="regular" alignment="center">
        Guarantors will appear here once they are added
      </Text>
      <br />
      <Text as="span" tone="subdued" alignment="center">
        If no guarantor is elected it will not appear in the customer portal
      </Text>

      <br />

      <InlineStack align="center">
        <Button onClick={() => setShowGuarantorModal(true)}>
          Add guarantor
        </Button>
      </InlineStack>
    </Box>
  );

  const [showGuarantorModal, setShowGuarantorModal] = useState(false);
  const toggleShowGuarantorModal = () => {
    setShowGuarantorModal(!showGuarantorModal);
  };

  const [skipGuarantorQuery, setSkipGuarantorQuery] = useState(true);

  const [guarantorQueryValue, setGuarantorQueryValue] = useState("");
  const [guarantorSearchValue] = useDebounce(guarantorQueryValue, 300);

  const {
    data: searchedGuarantors = [],
    isLoading: isSearchGuarantorsLoading = false,
    isFetching: isSearchGuarantorsFetching,
  } = useSearchGuarantorsQuery(
    {
      opportunity_id: opportunityId,
      search: guarantorSearchValue,
    },
    { skip: skipGuarantorQuery }
  );

  const [hasSearchFocus, setHasSearchFocus] = useState(false);
  const [guarantorOptions, setGuarantorOptions] = useState([]);
  const [selectedGuarantorsOptions, setSelectedGuarantorsOptions] = useState(
    []
  );
  const [canCreateGuarantor, setCanCreateGuarantor] = useState(false);

  useEffect(() => {
    if (guarantorSearchValue.length > 0) {
      setSkipGuarantorQuery(false);
    }
  }, [guarantorSearchValue]);

  useEffect(() => {
    if (searchedGuarantors.length > 0) {
      let gOptions = [];
      for (let i = 0; i < searchedGuarantors.length; i++) {
        gOptions.push({
          key: i,
          label:
            searchedGuarantors[i].name +
            " - " +
            searchedGuarantors[i].description,
          value: searchedGuarantors[i],
        });
      }

      setGuarantorOptions(gOptions);
    }
  }, [searchedGuarantors]);

  const handleGuarantorQueryChange = (queryValue) => {
    setGuarantorQueryValue(queryValue);
    setGuarantorOptions([]);
  };

  const guarantorSearchTextField = (
    <Autocomplete.TextField
      onChange={handleGuarantorQueryChange}
      value={guarantorQueryValue}
      prefix={<Icon source={SearchIcon} tone="base" />}
      placeholder="Filter guarantors..."
      focus={hasSearchFocus}
    />
  );

  useEffect(() => {
    if (guarantorQueryValue) {
      setHasSearchFocus(true);
    }
  }, [guarantorQueryValue]);

  const updateGuarantorSelection = useCallback(
    (selected) => {
      if (selected.length > 0) {
        setSelectedGuarantorsOptions(selected);
        setSkipGuarantorQuery(true);
        setGuarantorQueryValue(
          selected[0].name + " - " + selected[0].description
        );
        setCanCreateGuarantor(true);
      }
    },
    [guarantorOptions]
  );

  const [createGuarantor, { isLoading: isCreatingGuarantor }] =
    useCreateGuarantorMutation();

  const createNewGuarantor = () => {
    return createGuarantor({
      opportunityId: opportunityId,
      body: selectedGuarantorsOptions[0],
    })
      .unwrap()
      .then(({ data }) => {
        toggleShowGuarantorModal();

        refetchGuarantors();

        return { status: "success" };
      })
      .catch((error) => {
        alert("Failed to create guarantor.");
        console.log(error);
      });
  };

  const handleCloseGuarantorsModal = () => {
    setShowGuarantorModal(false);
    setGuarantorQueryValue("");
    setGuarantorOptions([]);
  };

  const guarantorsSearchEmptyState = guarantorQueryValue &&
    guarantorQueryValue.length > 1 && (
      <>
        <Icon source={SearchIcon} />
        <div style={{ textAlign: "center", padding: "12px" }}>
          <BlockStack>
            <p>{`Could not find any results for "${guarantorQueryValue}".`}</p>
          </BlockStack>
        </div>
      </>
    );

  const addGuarantorsModal = (
    <Modal
      open={showGuarantorModal}
      onClose={handleCloseGuarantorsModal}
      title={"Search and select guarantor to add to this opportunity"}
      primaryAction={{
        content: "Add Guarantor",
        onAction: createNewGuarantor,
        disabled: !canCreateGuarantor,
        loading: isCreatingGuarantor,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseGuarantorsModal,
        },
      ]}
    >
      <Modal.Section>
        <Autocomplete
          options={guarantorOptions}
          selected={selectedGuarantorsOptions}
          onSelect={updateGuarantorSelection}
          textField={guarantorSearchTextField}
          loading={isSearchGuarantorsLoading || isSearchGuarantorsFetching}
          emptyState={guarantorsSearchEmptyState}
          preferredPosition={"below"}
        />
      </Modal.Section>
    </Modal>
  );

  const [requireCreditApplication, setRequireCreditApplication] =
    useState(false);

  useEffect(() => {
    if (opportunity)
      setRequireCreditApplication(
        opportunity?.attributes?.requires_credit_application
      );
  }, [opportunity]);

  const updateRequireCreditApplication = (value) => {
    setRequireCreditApplication(value);

    let form = { requires_credit_application: value };

    return updateOpportunity({ id: opportunityId, ...form })
      .unwrap()
      .then(() => {
        showToastWithMessage(`Opportunity updated`);
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [selectedAcceptance, setSelectedAcceptance] = useState(false);

  const updateSelectedAcceptance = (value) => {
    setSelectedAcceptance(value);

    const form = { equipment_acceptance: value };

    return updateOpportunity({ id: opportunityId, ...form })
      .unwrap()
      .then(() => {
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (opportunity) {
      if (opportunity.attributes.selected_credit_submission_id) {
        refetchCreditSubmission();
      }

      if (opportunity.attributes.equipment_acceptance) {
        setSelectedAcceptance(true);
      }
    }
  }, [opportunity, refetchCreditSubmission]);

  const navigateToClientPortalPage = (page) => {
    window.open(`/opportunities/${opportunityId}/share/${page}`, "_blank");
  };

  const [isCopyingLink, setIsCopyingLink] = useState({});

  const clientPortalLinks = [
    {
      name: "Purchase Options",
      path: "purchase_options",
    },
    {
      name: "Credit Application",
      path: "credit_application",
    },
    {
      name: "Payment Authorization",
      path: "payment_authorization",
    },
    {
      name: "Information Request",
      path: "info_request",
    },
    {
      name: "Document Envelopes",
      path: "document_envelopes",
    },
  ];

  const copyLinkToClipboard = (path) => {
    setIsCopyingLink((prev) => ({ ...prev, [path]: true }));
    const url = `${process.env.APPLICATION_HOST}/opportunities/${opportunityId}/share/${path}`;
    copyTextToClipboard(url).then(() => {
      setTimeout(() => {
        setIsCopyingLink((prev) => ({ ...prev, [path]: false }));
        showToastWithMessage("Link copied");
      }, 300);
    });
  };

  const linksSideCard = (
    <Card>
      <Box>
        <Text as="h2" variant="headingMd">
          Links
        </Text>
      </Box>
      <Box paddingBlockStart="400">
        <BlockStack align="start" inlineAlign="start" gap="200">
          {clientPortalLinks.map((link) => (
            <Box
              key={link.path}
              padding="100"
              width="100%"
              borderRadius={"100"}
              className="FortifyCustomerPortal__TaskItem"
            >
              <InlineStack
                align={"space-between"}
                blockAlign="start"
                wrap={false}
                gap="200"
              >
                <InlineStack gap="200">
                  <Link onClick={() => navigateToClientPortalPage(link.path)}>
                    {link.name}
                  </Link>
                </InlineStack>
                <Button
                  icon={ClipboardIcon}
                  variant="plain"
                  size="small"
                  onClick={() => copyLinkToClipboard(link.path)}
                  loading={isCopyingLink[link.path]}
                />
              </InlineStack>
            </Box>
          ))}
        </BlockStack>
      </Box>
    </Card>
  );

  const guarantorSideCard = (
    <Card>
      <BlockStack gap="300">
        <InlineGrid columns="1fr auto">
          <Text as="h2" variant="headingMd">
            Credit
          </Text>
          {/* <ButtonGroup>
            <Button variant="plain">Action</Button>
          </ButtonGroup> */}
        </InlineGrid>
        <FormLayout>
          <Checkbox
            label={"Require credit application"}
            checked={requireCreditApplication}
            onChange={updateRequireCreditApplication}
            disabled={isUpdating}
            helpText="This will present the credit authorization in the client portal after terms selection."
          />

          <Checkbox
            label={"Acceptance confirmed"}
            checked={selectedAcceptance}
            onChange={updateSelectedAcceptance}
            disabled={isUpdating}
            helpText="The customer has confirmed the acceptance of the equipment."
          />
        </FormLayout>
        <Bleed marginInlineStart="400" marginInlineEnd="400">
          <Divider />
        </Bleed>
        <BlockStack gap="200">
          <InlineGrid columns="1fr auto">
            <Text as="h2" variant="headingSm">
              Guarantors
            </Text>
            <ButtonGroup>
              <Button
                variant="plain"
                onClick={() => setShowGuarantorModal(true)}
              >
                Add guarantor
              </Button>
            </ButtonGroup>
          </InlineGrid>

          <Bleed
            marginInlineStart="400"
            marginInlineEnd="400"
            marginBlockEnd="400"
          >
            <GuarantorList
              guarantors={guarantors}
              isLoading={isLoadingGuarantors}
              opportunityId={opportunityId}
              onAddGuarantor={() => setShowGuarantorModal(true)}
            />
          </Bleed>
        </BlockStack>
      </BlockStack>
    </Card>
  );

  const [loadingDocuments, setLoadingDocuments] = useState({});

  const setDocumentToLoadingState = (documentId, state) => {
    const loading = JSON.parse(JSON.stringify(loadingDocuments));
    loading[documentId] = state;
    setLoadingDocuments(loading);
  };

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles, handleUpload, documentId) => {
      let acceptedFilenames = [];
      for (let i = 0; i < acceptedFiles.length; i++) {
        acceptedFilenames.push(acceptedFiles[i].name);
      }

      // Set loading to true
      setDocumentToLoadingState(documentId, true);

      handleUpload(acceptedFiles);
    },
    []
  );

  const [standardDocuments, setStandardDocuments] = useState([]);
  const [selectedStandardDocuments, setSelectedStandardDocuments] = useState(
    []
  );

  // Build preset standard documents
  useEffect(() => {
    let previousYearPresetDocs = [
      "- Year End Financials (Balance Sheet + P&L) audited/reviewed where available",
      "Corp Tax Return",
    ];
    let currentYearPresetDocs = ["- Interim Financials (Balance Sheet + P&L)"];

    let today = new Date();
    let currentYear = today.getFullYear();
    let currentMonth = today.getMonth();

    let presetDocs = [];

    // Add current year docs
    for (let i = 0; i < currentYearPresetDocs.length; i++) {
      let documentName = currentYear + " " + currentYearPresetDocs[i];
      presetDocs.push({ value: documentName, label: documentName });
    }

    // Add last 3 year docs
    for (let i = 0; i < previousYearPresetDocs.length; i++) {
      for (let j = 1; j <= 3; j++) {
        let year = currentYear - j;
        let documentName = year + " " + previousYearPresetDocs[i];
        presetDocs.push({ value: documentName, label: documentName });
      }
    }

    // Add last 3 months
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    for (let i = 1; i <= 3; i++) {
      let month = currentMonth - i;
      if (month < 0) month = 12 + month;
      let monthName = monthNames[month];

      let documentName = monthName + " Bank Statement";
      presetDocs.push({ value: documentName, label: documentName });
    }

    presetDocs.push({
      value: "Other pertinent information",
      label: "Other pertinent information",
    });

    presetDocs.push({
      value: "Driver's License",
      label: "Driver's License",
    });

    setStandardDocuments(presetDocs);
  }, []);

  const [documentInput, setDocumentInput] = useState("");

  useEffect(() => {
    if (opportunityAttachments) {
      let list = {};
      let listChecked = {};
      let loading = {};
      let listNames = [];
      for (let i = 0; i < opportunityAttachments.length; i++) {
        let documentName = opportunityAttachments[i].attributes.name;
        let documentId = opportunityAttachments[i].id;
        list[documentName] = opportunityAttachments[i];
        listChecked[documentName] = true;
        loading[documentId] = false;
        listNames.push(documentName);
      }

      setLoadingDocuments(loading);
      setSelectedStandardDocuments(listNames);
    }
  }, [opportunityAttachments]);

  const changeCustomerDocumentListChecked = (document) => {
    let listToAdd = [...document];
    for (let i = 0; i < opportunityAttachments.length; i++) {
      let documentName = opportunityAttachments[i].attributes.name;
      let index = listToAdd.indexOf(documentName);
      if (index < 0) {
        listToAdd.push(documentName);
      }
    }

    setSelectedStandardDocuments(listToAdd);
  };

  const addToCustomerDocumentList = (publicDocuments = true) => {
    const listToAdd = [...selectedStandardDocuments];
    if (documentInput.length > 0) listToAdd.push(documentInput);
    for (let i = 0; i < opportunityAttachments.length; i++) {
      let documentName = opportunityAttachments[i].attributes.name;
      let index = listToAdd.indexOf(documentName);
      if (index >= 0) {
        listToAdd.splice(index, 1);
      }
    }

    return createOpportunityAttachments({
      opportunityId: opportunityId,
      body: {
        documents: listToAdd,
        public: publicDocuments,
      },
    })
      .unwrap()
      .then(() => {
        if (customerDocumentsModalOpen) toggleCustomerDocumentsModalOpen();
        if (internalFundingRequirementsModalOpen)
          toggleInternalFundingRequirementsModalOpen();
        refetchOpportunityAttachments();
        setDocumentInput("");
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        setDocumentInput("");
        if (customerDocumentsModalOpen) toggleCustomerDocumentsModalOpen();
        if (internalFundingRequirementsModalOpen)
          toggleInternalFundingRequirementsModalOpen();
      });
  };

  const [customerDocumentsModalOpen, setCustomerDocumentsModalOpen] =
    useState(false);
  const toggleCustomerDocumentsModalOpen = () => {
    setCustomerDocumentsModalOpen(!customerDocumentsModalOpen);
  };

  const addToCustomerDocumentsModal = (
    <Modal
      open={customerDocumentsModalOpen}
      onClose={toggleCustomerDocumentsModalOpen}
      title="Add document"
      primaryAction={{
        content: "Add document",
        onAction: () => {
          addToCustomerDocumentList(true);
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: toggleCustomerDocumentsModalOpen,
        },
      ]}
    >
      <Modal.Section flush>
        <OptionList
          title="Preset Documents"
          onChange={changeCustomerDocumentListChecked}
          options={standardDocuments}
          selected={selectedStandardDocuments}
          allowMultiple
        />

        <div style={{ padding: "8px 16px 16px" }}>
          <BlockStack>
            <p>
              <Text as="span" fontWeight="semibold">
                Or, enter a custom document name below.
              </Text>
            </p>
          </BlockStack>
        </div>
      </Modal.Section>

      <Modal.Section>
        <TextField
          label="Document name"
          value={documentInput}
          onChange={(value) => setDocumentInput(value)}
          autoComplete="off"
          helpText="Name should describe the expected document"
        />
      </Modal.Section>
    </Modal>
  );

  const [createOpportunityAttachments] =
    useCreateOpportunityAttachmentsMutation();

  const [updateOpportunityAttachment, { isLoading: isUpdating }] =
    useUpdateOpportunityAttachmentMutation();

  const [purgeOpportunityAttachment] = usePurgeOpportunityAttachmentMutation();

  const [deleteOpportunityAttachment] =
    useDeleteOpportunityAttachmentMutation();

  const [createOpportunityEvent] = useCreateOpportunityEventMutation();

  const handleCreateOpportunityEvent = useCallback(
    (type) => {
      return createOpportunityEvent({
        opportunityId: opportunityId,
        type: type,
        contact_id: currentContact.id,
      })
        .unwrap()
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [createOpportunityEvent, opportunityId]
  );

  const handleAttachment = (uploads, document) => {
    let documentId = document.id;
    let files = uploads;

    return updateOpportunityAttachment({
      opportunityId: opportunityId,
      id: documentId,
      patch: {
        documents: files,
      },
    })
      .unwrap()
      .then(() => {
        refetchOpportunityAttachments();
        handleCreateOpportunityEvent("OpportunityFileUploaded");
        return { status: "success" };
      })
      .catch((error) => {
        setDocumentToLoadingState(documentId, false);
        console.log(error);
      });
  };

  const purgeAttachment = (document, dIndex) => {
    let documentId = document.id;
    let attachmentId = document.attributes.attachment_id[dIndex];

    setDocumentToLoadingState(documentId, true);

    return purgeOpportunityAttachment({
      opportunityId: opportunityId,
      id: documentId,
      attachmentId: attachmentId,
      dIndex: dIndex,
    })
      .unwrap()
      .then(() => {
        refetchOpportunityAttachments();
        return { status: "success" };
      })
      .catch((error) => {
        setDocumentToLoadingState(documentId, false);
        console.log(error);
      });
  };

  const deleteDocument = (document) => {
    let documentId = document.id;
    setDocumentToLoadingState(documentId, true);

    return deleteOpportunityAttachment({
      opportunityId: opportunityId,
      id: documentId,
    })
      .unwrap()
      .then(() => {
        refetchOpportunityAttachments();
        return { status: "success" };
      })
      .catch((error) => {
        setDocumentToLoadingState(documentId, false);
        console.log(error);
      });
  };

  const [documentPopovers, setDocumentPopovers] = useState([]);

  useEffect(() => {
    if (opportunityAttachments) {
      let documentPopoverStatus = [];
      for (let i = 0; i < opportunityAttachments.length; i++) {
        documentPopoverStatus.push(false);
      }

      setDocumentPopovers(documentPopoverStatus);
    }
  }, [opportunityAttachments]);

  const toggleDocumentPopover = (index) => {
    let documentPopoverStatus = JSON.parse(JSON.stringify(documentPopovers));
    documentPopoverStatus[index] = !documentPopoverStatus[index];

    setDocumentPopovers(documentPopoverStatus);
  };

  const customerDocuments = (
    <Card padding="0">
      <CardBoxTitle>
        <Text variant="headingMd" as="h6">
          Customer documents for upload
        </Text>
      </CardBoxTitle>
      <Box padding="400">
        <p>All document upload items are visible in customer portal</p>
      </Box>

      {opportunityAttachments &&
        opportunityAttachments.map(
          (document, index) =>
            document.attributes.public && (
              <Box as="section" key={index} padding="400">
                <Text variant="headingMd" as="h6">
                  {document.attributes.document === "" && (
                    <InlineStack align="space-between">
                      <InlineStack gap="400">
                        <Icon tone="subdued" source={AttachmentIcon} />
                        <Text as="span" fontWeight="semibold">
                          {document.attributes.name}
                        </Text>
                      </InlineStack>

                      <Popover
                        active={documentPopovers[index]}
                        activator={
                          <div
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              toggleDocumentPopover(index);
                            }}
                          >
                            <Icon source={MenuHorizontalIcon} />
                          </div>
                        }
                        autofocusTarget="first-node"
                        onClose={() => {
                          toggleDocumentPopover(index);
                        }}
                      >
                        <ActionList
                          actionRole="menuitem"
                          items={[
                            {
                              content: "Remove folder",
                              disabled:
                                document.attributes.document.length != 0,
                              onAction: () => {
                                deleteDocument(document);
                              },
                            },
                          ]}
                        />
                      </Popover>
                    </InlineStack>
                  )}
                </Text>
                <DirectUploadProvider
                  multiple={true}
                  onSuccess={(uploads) => {
                    handleAttachment(uploads, document);
                  }}
                  render={({ handleUpload, uploads, ready }) => (
                    <>
                      {/* Spinner when loading */}
                      {loadingDocuments[document.id] && (
                        <div
                          style={{
                            position: "relative",
                            minHeight: "118px",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "32px",
                              left: "calc(50% - 22px)",
                            }}
                          >
                            <Spinner
                              accessibilityLabel="Spinner example"
                              size="large"
                            />
                          </div>
                        </div>
                      )}

                      {/* Dropzone and uploaded content */}
                      {!loadingDocuments[document.id] && (
                        <>
                          {document.attributes.document.length > 0 && (
                            <>
                              <InlineStack align="space-between">
                                <InlineStack>
                                  <div>
                                    <Icon
                                      tone="success"
                                      source={CheckCircleIcon}
                                    />
                                  </div>
                                  <div style={{ marginLeft: "15px" }}>
                                    <Text as="span" tone="success">
                                      <b>{document.attributes.name}</b>
                                    </Text>
                                  </div>
                                </InlineStack>

                                <Popover
                                  active={documentPopovers[index]}
                                  activator={
                                    <div
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        toggleDocumentPopover(index);
                                      }}
                                    >
                                      <Icon source={MenuHorizontalIcon} />
                                    </div>
                                  }
                                  autofocusTarget="first-node"
                                  onClose={() => {
                                    toggleDocumentPopover(index);
                                  }}
                                >
                                  <ActionList
                                    actionRole="menuitem"
                                    items={[
                                      {
                                        content: "Remove folder",
                                        disabled:
                                          document.attributes.document.length !=
                                          0,
                                        onAction: () => {
                                          deleteDocument(document);
                                        },
                                      },
                                    ]}
                                  />
                                </Popover>
                              </InlineStack>

                              <br />

                              <DropZone
                                allowMultiple={true}
                                onDrop={(
                                  _dropFiles,
                                  acceptedFiles,
                                  _rejectedFiles
                                ) => {
                                  handleDropZoneDrop(
                                    _dropFiles,
                                    acceptedFiles,
                                    _rejectedFiles,
                                    handleUpload,
                                    document.id
                                  );
                                }}
                              >
                                <DropZone.FileUpload actionHint="or drop files to upload" />
                              </DropZone>

                              <br />

                              {document.attributes.document.map((d, dIndex) => (
                                <BlockStack key={dIndex}>
                                  <div>
                                    <div
                                      style={{
                                        backgroundColor: "#F6F6F7",
                                        borderBottom: "1px solid #EBEBEB",
                                        padding: "1em",
                                      }}
                                    >
                                      <InlineStack align="space-between">
                                        <Text>
                                          {document.attributes.filenames[dIndex]
                                            ? document.attributes.filenames[
                                                dIndex
                                              ]
                                            : "-"}
                                        </Text>

                                        <InlineStack>
                                          <span
                                            style={{
                                              marginLeft: "30px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <Link
                                              url={d}
                                              removeUnderline={true}
                                            >
                                              View file
                                            </Link>
                                          </span>
                                          <span
                                            onClick={() => {
                                              purgeAttachment(document, dIndex);
                                            }}
                                            style={{
                                              marginLeft: "30px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <Icon
                                              source={DeleteIcon}
                                              tone="critical"
                                            />
                                          </span>
                                        </InlineStack>
                                      </InlineStack>
                                    </div>
                                  </div>
                                </BlockStack>
                              ))}
                            </>
                          )}

                          <br />

                          {document.attributes.document.length == 0 && (
                            <BlockStack>
                              <InlineStack align="space-between">
                                <InlineStack>
                                  <div>
                                    <Icon source={AttachmentIcon} />
                                  </div>
                                  <div style={{ marginLeft: "15px" }}>
                                    <Text as="span">
                                      <b>{document.attributes.name}</b>
                                    </Text>
                                  </div>
                                </InlineStack>

                                <Popover
                                  active={documentPopovers[index]}
                                  activator={
                                    <div
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        toggleDocumentPopover(index);
                                      }}
                                    >
                                      <Icon source={MenuHorizontalIcon} />
                                    </div>
                                  }
                                  autofocusTarget="first-node"
                                  onClose={() => {
                                    toggleDocumentPopover(index);
                                  }}
                                >
                                  <ActionList
                                    actionRole="menuitem"
                                    items={[
                                      {
                                        content: "Remove folder",
                                        disabled:
                                          document.attributes.document.length !=
                                          0,
                                        onAction: () => {
                                          deleteDocument(document);
                                        },
                                      },
                                    ]}
                                  />
                                </Popover>
                              </InlineStack>

                              <br />

                              <DropZone
                                allowMultiple={true}
                                onDrop={(
                                  _dropFiles,
                                  acceptedFiles,
                                  _rejectedFiles
                                ) => {
                                  handleDropZoneDrop(
                                    _dropFiles,
                                    acceptedFiles,
                                    _rejectedFiles,
                                    handleUpload,
                                    document.id
                                  );
                                }}
                              >
                                <DropZone.FileUpload actionHint="or drop files to upload" />
                              </DropZone>
                            </BlockStack>
                          )}
                        </>
                      )}
                    </>
                  )}
                />
              </Box>
            )
        )}

      <Box padding="400">
        <Button fullWidth onClick={toggleCustomerDocumentsModalOpen}>
          <InlineStack align="center" blockAlign="center" gap="200">
            <Icon tone="subdued" source={PlusCircleIcon}></Icon>
            Add document
          </InlineStack>
        </Button>
      </Box>
    </Card>
  );

  const [
    internalFundingRequirementsModalOpen,
    setInternalFundingRequirementsModalOpen,
  ] = useState(false);
  const toggleInternalFundingRequirementsModalOpen = () => {
    setDocumentInput("");
    setInternalFundingRequirementsModalOpen(
      !internalFundingRequirementsModalOpen
    );
  };

  const addToInternalFundingRequirementsModal = (
    <Modal
      open={internalFundingRequirementsModalOpen}
      onClose={toggleInternalFundingRequirementsModalOpen}
      title="Add internal document"
      primaryAction={{
        content: "Add document",
        onAction: () => {
          addToCustomerDocumentList(false);
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: toggleInternalFundingRequirementsModalOpen,
        },
      ]}
    >
      <Modal.Section flush>
        <OptionList
          title="Preset Documents"
          onChange={changeCustomerDocumentListChecked}
          options={standardDocuments}
          selected={selectedStandardDocuments}
          allowMultiple
        />

        <div style={{ padding: "8px 16px 16px" }}>
          <BlockStack>
            <p>
              <Text as="span" fontWeight="semibold">
                Or, enter a custom document name below.
              </Text>
            </p>
          </BlockStack>
        </div>
      </Modal.Section>

      <Modal.Section>
        <TextField
          label="Document name"
          value={documentInput}
          onChange={(value) => setDocumentInput(value)}
          autoComplete="off"
          helpText="Name should describe the expected document"
        />
      </Modal.Section>
    </Modal>
  );

  const internalFundingRequirements = (
    <Card padding="0">
      <CardBoxTitle>
        <Text variant="headingMd" as="h6">
          Internal funding requirements
        </Text>
      </CardBoxTitle>

      {opportunityAttachments &&
        opportunityAttachments.map(
          (document, index) =>
            !document.attributes.public && (
              <Box key={index} padding="400" as="section">
                <Text variant="headingMd" as="h6">
                  {document.attributes.document === "" && (
                    <InlineStack align="space-between">
                      <InlineStack gap="400">
                        <Icon tone="subdued" source={AttachmentIcon} />
                        <Text as="span" fontWeight="semibold">
                          {document.attributes.name}
                        </Text>
                      </InlineStack>

                      <Popover
                        active={documentPopovers[index]}
                        activator={
                          <div
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              toggleDocumentPopover(index);
                            }}
                          >
                            <Icon source={MenuHorizontalIcon} />
                          </div>
                        }
                        autofocusTarget="first-node"
                        onClose={() => {
                          toggleDocumentPopover(index);
                        }}
                      >
                        <ActionList
                          actionRole="menuitem"
                          items={[
                            {
                              content: "Remove folder",
                              disabled:
                                document.attributes.document.length != 0,
                              onAction: () => {
                                deleteDocument(document);
                              },
                            },
                          ]}
                        />
                      </Popover>
                    </InlineStack>
                  )}
                </Text>
                <DirectUploadProvider
                  multiple={false}
                  onSuccess={(uploads) => {
                    handleAttachment(uploads, document);
                  }}
                  render={({ handleUpload, uploads, ready }) => (
                    <>
                      {/* Spinner when loading */}
                      {loadingDocuments[document.id] && (
                        <div
                          style={{
                            position: "relative",
                            minHeight: "118px",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "32px",
                              left: "calc(50% - 22px)",
                            }}
                          >
                            <Spinner
                              accessibilityLabel="Spinner example"
                              size="large"
                            />
                          </div>
                        </div>
                      )}

                      {/* Dropzone and uploaded content */}
                      {!loadingDocuments[document.id] && (
                        <>
                          {document.attributes.document.length > 0 && (
                            <>
                              <InlineStack align="space-between">
                                <InlineStack>
                                  <div>
                                    <Icon
                                      tone="success"
                                      source={CheckCircleIcon}
                                    />
                                  </div>
                                  <div style={{ marginLeft: "15px" }}>
                                    <Text as="span" tone="success">
                                      <b>{document.attributes.name}</b>
                                    </Text>
                                  </div>
                                </InlineStack>

                                <Popover
                                  active={documentPopovers[index]}
                                  activator={
                                    <div
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        toggleDocumentPopover(index);
                                      }}
                                    >
                                      <Icon source={MenuHorizontalIcon} />
                                    </div>
                                  }
                                  autofocusTarget="first-node"
                                  onClose={() => {
                                    toggleDocumentPopover(index);
                                  }}
                                >
                                  <ActionList
                                    actionRole="menuitem"
                                    items={[
                                      {
                                        content: "Remove folder",
                                        disabled:
                                          document.attributes.document.length !=
                                          0,
                                        onAction: () => {
                                          deleteDocument(document);
                                        },
                                      },
                                    ]}
                                  />
                                </Popover>
                              </InlineStack>

                              <br />

                              <DropZone
                                allowMultiple={true}
                                onDrop={(
                                  _dropFiles,
                                  acceptedFiles,
                                  _rejectedFiles
                                ) => {
                                  handleDropZoneDrop(
                                    _dropFiles,
                                    acceptedFiles,
                                    _rejectedFiles,
                                    handleUpload,
                                    document.id
                                  );
                                }}
                              >
                                <DropZone.FileUpload actionHint="or drop files to upload" />
                              </DropZone>

                              <br />

                              {document.attributes.document.map((d, dIndex) => (
                                <BlockStack key={dIndex}>
                                  <div>
                                    <div
                                      style={{
                                        backgroundColor: "#F6F6F7",
                                        borderBottom: "1px solid #EBEBEB",
                                        padding: "1em",
                                      }}
                                    >
                                      <InlineStack align="space-between">
                                        <Text>
                                          {document.attributes.filenames[dIndex]
                                            ? document.attributes.filenames[
                                                dIndex
                                              ]
                                            : "-"}
                                        </Text>

                                        <InlineStack>
                                          <span
                                            style={{
                                              marginLeft: "30px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <Link
                                              url={d}
                                              removeUnderline={true}
                                            >
                                              View file
                                            </Link>
                                          </span>
                                          <span
                                            onClick={() => {
                                              purgeAttachment(document, dIndex);
                                            }}
                                            style={{
                                              marginLeft: "30px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <Icon
                                              source={DeleteIcon}
                                              tone="critical"
                                            />
                                          </span>
                                        </InlineStack>
                                      </InlineStack>
                                    </div>
                                  </div>
                                </BlockStack>
                              ))}
                            </>
                          )}

                          <br />

                          {document.attributes.document.length == 0 && (
                            <BlockStack>
                              <InlineStack align="space-between">
                                <InlineStack>
                                  <div>
                                    <Icon source={AttachmentIcon} />
                                  </div>
                                  <div style={{ marginLeft: "15px" }}>
                                    <Text as="span">
                                      <b>{document.attributes.name}</b>
                                    </Text>
                                  </div>
                                </InlineStack>

                                <Popover
                                  active={documentPopovers[index]}
                                  activator={
                                    <div
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        toggleDocumentPopover(index);
                                      }}
                                    >
                                      <Icon source={MenuHorizontalIcon} />
                                    </div>
                                  }
                                  autofocusTarget="first-node"
                                  onClose={() => {
                                    toggleDocumentPopover(index);
                                  }}
                                >
                                  <ActionList
                                    actionRole="menuitem"
                                    items={[
                                      {
                                        content: "Remove folder",
                                        disabled:
                                          document.attributes.document.length !=
                                          0,
                                        onAction: () => {
                                          deleteDocument(document);
                                        },
                                      },
                                    ]}
                                  />
                                </Popover>
                              </InlineStack>

                              <br />

                              <DropZone
                                allowMultiple={true}
                                onDrop={(
                                  _dropFiles,
                                  acceptedFiles,
                                  _rejectedFiles
                                ) => {
                                  handleDropZoneDrop(
                                    _dropFiles,
                                    acceptedFiles,
                                    _rejectedFiles,
                                    handleUpload,
                                    document.id
                                  );
                                }}
                              >
                                <DropZone.FileUpload actionHint="or drop files to upload" />
                              </DropZone>
                            </BlockStack>
                          )}
                        </>
                      )}
                    </>
                  )}
                />
              </Box>
            )
        )}

      <Box as="section" padding="400">
        <Button fullWidth onClick={toggleInternalFundingRequirementsModalOpen}>
          <InlineStack align="center" blockAlign="center">
            <Icon tone="subdued" source={PlusCircleIcon}></Icon>
            Add document
          </InlineStack>
        </Button>
      </Box>
    </Card>
  );

  const fundingChecklistLayout = (
    <Layout>
      <Layout.Section>
        <BlockStack gap="400">
          {documentPackage}
          {customerDocuments}
        </BlockStack>
      </Layout.Section>

      <Layout.Section variant="oneThird">
        <BlockStack gap="400">
          {linksSideCard}
          {guarantorSideCard}
          {selectedLenderCard}
          {internalFundingRequirements}
        </BlockStack>
      </Layout.Section>

      {addGuarantorsModal}
      {addToCustomerDocumentsModal}
      {addToInternalFundingRequirementsModal}
      {generateDocsModal}
    </Layout>
  );

  return fundingChecklistLayout;
};

export default OpportunitiesFundingChecklist;
