import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import {
  Card,
  Page,
  BlockStack,
  InlineStack,
  InlineGrid,
  Button,
  Layout,
  Labelled,
  Modal,
  Toast,
  Banner,
  Text,
} from "@shopify/polaris";

import {
  useGetAdminAccountQuery,
  useCreateAdminAccountReviewMutation,
} from "../../../services/api";

import AccountForm from "../../../components/Account/Form";
import { formatLongDateTime } from "../../../utilities";
import FadeIn from "../../../components/FadeIn";
import LoadingScreen from "../../../components/Auth/Loading";

import { CurrentContactContext } from "../../../contexts/Contact";

const AdminLenderShow = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { currentContact } = useContext(CurrentContactContext)

  const { id: accountId } = useParams();

  useEffect(() => {
    if (!accountId) {
      navigateToAdminAccountsIndex();
    }
  }, [accountId, navigateToAdminAccountsIndex]);

  const { account: accountObject } = location?.state?.account
    ? location.state
    : {
        account: { attributes: {} },
      };

  const { data: account = accountObject } = useGetAdminAccountQuery(accountId);
  const [createAccountReview, { isLoading: isCreatingAccountReview }] =
    useCreateAdminAccountReviewMutation();

  const navigateToAdminAccountsIndex = useCallback(() => {
    history.push("/admin");
  }, [history]);

  const navigateToOpportunityShow = useCallback(
    (clientId, opportunityId) => {
      history.push(`/clients/${clientId}/opportunities/${opportunityId}`);
    },
    [history]
  );

  const [showEditAccountDetailsModal, setShowAccountDetailsModal] =
    useState(false);
  const handleEditAccountDetails = () => {
    setShowAccountDetailsModal(true);
  };
  const handleCloseModal = () => {
    setShowAccountDetailsModal(false);
    setShowReviewAccountModal(false);
  };

  const [accountFormDirty, setAccountFormDirty] = useState(false);
  const handleAccountEdit = (isDirty) => {
    setAccountFormDirty(isDirty);
  };
  const handleAccountUpdate = (data) => {
    handleCloseModal();
    setShowToast(true);
    setToastMessage("Account updated.");
  };

  const accountFormRef = useRef();

  const editAccountModal = (
    <Modal
      open={showEditAccountDetailsModal}
      onClose={handleCloseModal}
      title="Edit account"
      primaryAction={{
        content: "Save",
        onAction: () => accountFormRef.current.handleSubmitForm(),
        disabled: !accountFormDirty,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseModal,
        },
      ]}
    >
      <AccountForm
        ref={accountFormRef}
        account={account}
        onCreateOrUpdate={handleAccountUpdate}
        onEdit={handleAccountEdit}
        isAdminContext={true}
      />
    </Modal>
  );

  const [showReviewAccountModal, setShowReviewAccountModal] = useState(false);
  const handleReviewAccount = () => {
    return createAccountReview({
      accountId: accountId,
    })
      .unwrap()
      .then(({ data }) => {
        handleCloseModal();
        setShowToast(true);
        setToastMessage("Account reviewed.");
        return { status: "success" };
      })
      .catch((error) => {
        alert("Failed to add review.");
        console.log(error);
      });
  };

  const reviewAccountModal = (
    <Modal
      open={showReviewAccountModal}
      onClose={handleCloseModal}
      title="Review account"
      primaryAction={{
        content: "Add review",
        onAction: handleReviewAccount,
        loading: isCreatingAccountReview,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseModal,
        },
      ]}
      sectioned
    >
      Please check the legal business name and all other account details before
      adding your review.
    </Modal>
  );

  const [showToast, setShowToast] = useState(location?.state?.showToast);
  const [toastMessage, setToastMessage] = useState(
    location?.state?.toastMessage
  );
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    const { state: currentState } = location;
    if (currentState?.toastMessage) {
      delete currentState.toastMessage;
    }
    if (currentState?.showToast) {
      delete currentState.showToast;
    }
    window.history.replaceState({ ...currentState }, "");
    toggleShowToast();
    setToastMessage();
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  const reviewedByMessage = account.attributes.reviewed
    ? `Last reviewed by ${
        account.attributes.reviewed_by_name
      } on ${formatLongDateTime(new Date(account.attributes.reviewed_at))}`
    : "Needs review";

  const labelStyles = {
    minHeight: "36px",
    paddingTop: "4px",
  };

  const pageMarkup = (
    <FadeIn fadeIn>
      <Page
        title={account.attributes.name}
        subtitle={`${account.attributes.type} account`}
        secondaryActions={[
          {
            content: "Review",
            onAction: () => setShowReviewAccountModal(true),
          },
        ]}
        fullWidth
      >
        <Layout>
          {!account.attributes.reviewed && (
            <Layout.Section>
              <Banner
                title="This account has not been reviewed by a Fortify admin."
                action={{
                  content: "Review",
                  onAction: () => setShowReviewAccountModal(true),
                }}
                tone="warning"
              ></Banner>
            </Layout.Section>
          )}

          {/* Account details */}
          <Layout.Section primary>
            <Card>
              <div style={{ paddingBottom: "16px"}}>
                <InlineStack gap="200" align="space-between">
                  <Text variant="headingMd" as="h6">
                    Account information
                  </Text>

                  <Button  onClick={handleEditAccountDetails} variant="plain">
                    Edit account
                  </Button>
                </InlineStack>
              </div>

              <div>
                <InlineGrid gap="400" columns={2}>
                  <Labelled label="Legal name">
                    <div style={labelStyles}>
                      <BlockStack alignment="trailing" spacing="tight">
                        <p>{account.attributes.name}</p>
                      </BlockStack>
                    </div>
                  </Labelled>

                  <Labelled label="DBA">
                    <div style={labelStyles}>
                      <BlockStack alignment="trailing" spacing="tight">
                        <p>{account.attributes.dba || "-"}</p>
                      </BlockStack>
                    </div>
                  </Labelled>
                </InlineGrid>
              </div>

              <InlineStack>
                <Labelled label="Street address">
                  <div style={labelStyles}>
                    <BlockStack alignment="trailing" spacing="tight">
                      <p>{account.attributes.billing_street || "-"}</p>
                    </BlockStack>
                  </div>
                </Labelled>
              </InlineStack>

              <div>
                <InlineGrid gap="400" columns={3}>
                  <Labelled label="City">
                    <div style={labelStyles}>
                      <BlockStack alignment="trailing" spacing="tight">
                        <p>{account.attributes.billing_city || "-"}</p>
                      </BlockStack>
                    </div>
                  </Labelled>

                  <Labelled label="State">
                    <div style={labelStyles}>
                      <BlockStack alignment="trailing" spacing="tight">
                        <p>{account.attributes.billing_state || "-"}</p>
                      </BlockStack>
                    </div>
                  </Labelled>

                  <Labelled label="Zip code">
                    <div style={labelStyles}>
                      <BlockStack alignment="trailing" spacing="tight">
                        <p>{account.attributes.billing_postal_code || "-"}</p>
                      </BlockStack>
                    </div>
                  </Labelled>
                </InlineGrid>
              </div>

              {account.attributes.reviewed && (
                <div subdued>
                  <BlockStack>
                    <p>
                      <Text as="span" tone="subdued">
                        {reviewedByMessage}
                      </Text>
                    </p>
                  </BlockStack>
                </div>
              )}
            </Card>
          </Layout.Section>
        </Layout>

        {editAccountModal}
        {reviewAccountModal}
        {toastMarkup}
      </Page>
    </FadeIn>
  );

  return currentContact ? pageMarkup : <LoadingScreen />
};

export default AdminLenderShow;
