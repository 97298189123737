import React, { useState, useCallback, useEffect, useContext } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import { isMobile } from "react-device-detect";

import {
  Badge,
  Divider,
  Box,
  Link,
  Page,
  Banner,
  Layout,
  Button,
  TextField,
  Modal,
  Checkbox,
  Spinner,
  ProgressBar,
  InlineError,
  List,
  Icon,
  Thumbnail,
  Toast,
  ResourceItem,
  Truncate,
  ResourceList,
  BlockStack,
  LegacyStack,
  EmptyState,
  Tag,
  Text,
  InlineStack,
  Card,
  FormLayout,
  ChoiceList,
} from "@shopify/polaris";
import {
  NoteIcon,
  StoreIcon,
  CheckIcon,
  ViewIcon,
  EditIcon,
} from "@shopify/polaris-icons";

import OpportunityTab from "../../components/Nav/OpportunityTab";
import FinancingOptionList from "../../components/FinancingOption/List";
import DelayRender from "../../components/DelayRender";
import OpportunityShowSkeletonPage from "../../components/Opportunity/SkeletonPage";
import AddTagModalComponent from "../../components/Opportunity/AddTagModal";

import {
  useGetOpportunityQuery,
  useGetClientQuery,
  useUpdateOpportunityMutation,
  useUpdateOpportunityStageMutation,
  useCreateDocusignEmbeddedSigningMutation,
  useGetOpportunityCreditSubmissionsQuery,
  useGetOpportunityAttachmentsQuery,
  useCreateCreditReviewMutation,
  useGetOpportunityContactsQuery,
  useDeleteOpportunitiesMutation,
  usePushHubspotUpdateMutation,
  useUpdateTagOpportunitiesMutation,
  useGetTagsQuery,
} from "../../services/api";

import OpportunitiesFundingChecklist from "./FundingChecklist";
import OpportunitiesPaymentsAndReview from "./PaymentsAndReview";
import OpportunitiesEmbeddedSending from "./EmbeddedSending";

import InternalFundingRequirements from "./OpportunityDocuments/InternalFundingRequirements";

import VendorPaymentList from "../../components/VendorPayment/List";
import Confetti from "../../components/Confetti";
import CardBoxTitle from "../../components/Card/BoxTitle";
import {
  formatMillisecondsToMinutes,
  formatLongDateTime,
  formatNumericDate,
  capitalizeFirstLetter,
  formatCurrency,
} from "../../utilities";

import { CurrentContactContext } from "../../contexts/Contact";

import OpportunityCommentList from "../../components/Comment/OpportunityCommentList";
import FourZeroFour from "../../components/404";
import LoadingScreen from "../../components/Auth/Loading";

import emptySearchCompanies from "../../assets/emptySearchCompanies.svg";
import OpportunityOriginatorForm from "../../components/Opportunity/OriginatorForm";
import OpportunityContactAvatarList from "../../components/OpportunityContact/AvatarList";
import ShareModal from "../../components/Opportunity/ShareModal";
import TaskListProgressBar from "../../components/Opportunity/TaskListProgressBar";
import CreditPreview from "../../components/Opportunity/CreditPreview";
import { useToast } from "../../contexts/Toast";

const OpportunitiesShow = () => {
  const history = useHistory();
  const location = useLocation();
  const posthog = usePostHog();

  const { showToastWithMessage } = useToast();

  const {
    currentContact,
    currentContactHasPermission,
    isLoadingPermissions,
    isOrganizationAdmin,
    isVendorAdmin,
    isFinanceSpecialist,
    isCustomer,
  } = useContext(CurrentContactContext);

  const [openSelectTagModal, setOpenSelectTagModal] = useState(false);

  const { id: opportunityId, clientId } = useParams();

  const navigateToOpportunityShare = useCallback(() => {
    history.push({
      pathname: `/opportunities/${opportunityId}/share`,
    });
  }, [history, opportunityId]);

  const navigateToRequestAccess = useCallback(() => {
    history.push({
      pathname: `/request_access/clients/${clientId}/opportunities/${opportunityId}`,
    });
  }, [clientId, history, opportunityId]);

  const { opportunity: opportunityObject } = location?.state?.opportunity
    ? location.state
    : {
        opportunity: { attributes: {} },
      };

  useEffect(() => {
    // check if current contact has access to this opportunity
    // redirect to request access page for denied access
    if (isLoadingPermissions) return;
    if (isOrganizationAdmin) return;
    if (currentContact.attributes.opportunity_ids.includes(opportunityId)) {
      if (isCustomer) {
        navigateToOpportunityShare();
        return;
      } else {
        return;
      }
    }

    navigateToRequestAccess();
  }, [
    currentContact.attributes.opportunity_ids,
    isCustomer,
    isLoadingPermissions,
    isOrganizationAdmin,
    opportunityId,
    navigateToRequestAccess,
    navigateToOpportunityShare,
  ]);

  useEffect(() => {
    return () => posthog?.reset();
    // Dependencies are intentionally omitted, this effect should only run once
    // on mount and run the cleanup function on unmount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: opportunity = opportunityObject,
    isLoading: isLoadingOpportunity,
    refetch: refetchOpportunity,
    error: opportunityError,
    isSuccess: isOpportunitySuccess,
  } = useGetOpportunityQuery(opportunityId);

  const { data: tags, isLoading: fetchingTags } = useGetTagsQuery();

  useEffect(() => {
    if (!isLoadingOpportunity) {
      posthog?.group("opportunity", opportunityId, opportunity.attributes);
    }
  }, [isLoadingOpportunity, opportunityId, opportunity, posthog]);

  const [deleteOpportunities, { isLoading: isDeleting }] =
    useDeleteOpportunitiesMutation();

  const [updateTagOpportunities, { isLoading: isTagging }] =
    useUpdateTagOpportunitiesMutation();

  const [documentList, setDocumentList] = useState([]);
  useEffect(() => {
    if (opportunity?.attributes?.document_package_list) {
      setDocumentList(opportunity.attributes.document_package_list);
    }

    return () => setDocumentList([]);
  }, [opportunity.attributes.document_package_list]);

  const { data: client = { attributes: {} }, isLoading: isLoadingClient } =
    useGetClientQuery(clientId || opportunity.attributes.client_id, {
      skip: !clientId && !opportunity.attributes.client_id,
    });

  const {
    data: opportunityContacts = [],
    isLoading: isLoadingOpportunityContacts,
  } = useGetOpportunityContactsQuery(opportunityId, { skip: !opportunityId });

  const {
    data: opportunityAttachments,
    refetch: refetchOpportunityAttachments,
  } = useGetOpportunityAttachmentsQuery(
    { opportunityId: opportunityId, isPublic: false },
    { skip: !opportunityId }
  );

  const navigateToAccountShow = () => {
    if (isOrganizationAdmin) {
      history.push({
        pathname: `/admin/clients/${clientId}`,
      });
    } else {
      history.push({
        pathname: `/clients/${clientId}`,
        state: { client: client },
      });
    }
  };

  const [updateOpportunity, { isLoading: isUpdatingOpportunity }] =
    useUpdateOpportunityMutation();

  const [updateOpportunityStage, { isLoading: isUpdatingOpportunityStage }] =
    useUpdateOpportunityStageMutation();

  const [
    createDocusignEmbeddedSigning,
    { isLoading: isCreatingDocusignEmbeddedSigning },
  ] = useCreateDocusignEmbeddedSigningMutation();

  const [isLoadingAccessToggle, setIsLoadingAccessToggle] = useState(false);
  const [docusignRedirectUrl, setDocusignRedirectUrl] = useState();
  const [
    docusignRedirectUrlExpirationCounter,
    setDocusignRedirectUrlExpirationCounter,
  ] = useState(5000);

  const [showGenereateDocsError, setShowGenerateDocsError] = useState(false);
  const generateDocs = () => {
    const body = {
      opportunityId: opportunityId,
    };

    return createDocusignEmbeddedSigning({
      opportunityId: opportunityId,
      ...body,
    })
      .then((response) => {
        if (response.data.redirect_url) {
          const fiveMinutesAsMilliseconds = 5 * 60 * 1000;
          setDocusignRedirectUrlExpirationCounter(fiveMinutesAsMilliseconds);
          setDocusignRedirectUrl(response.data.redirect_url);
        }

        return { status: "success" };
      })
      .catch((error) => {
        setShowGenerateDocsError(true);
        console.log(error);

        return { status: "failure" };
      });
  };

  const isOpportunityTbd = opportunity?.attributes?.human_stage_name === "TBD";
  const isOpportunityClosedLost =
    opportunity?.attributes?.human_stage_name === "Closed lost";
  const isOpportunityClosedWon =
    opportunity?.attributes?.human_stage_name === "Closed won";
  const isOpportunityFunded =
    opportunity?.attributes?.human_stage_name === "Funded" ||
    isOpportunityClosedWon;
  const isOpportunityOpen =
    !isOpportunityClosedLost && !isOpportunityFunded && !isOpportunityTbd;

  const handleOpportunityStatusUpdate = useCallback(
    (status = "open") => {
      console.log("handleOpportunityStatusUpdate", status);
      return updateOpportunityStage({
        id: opportunityId,
        stage_name: status,
      })
        .unwrap()
        .then(() => {
          handleCloseModal();
          showToastWithMessage(
            `Opportunity ${
              status === "open"
                ? "opened"
                : status === "closed_won"
                ? "closed - won"
                : "closed - lost"
            }`
          );
          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [opportunityId, showToastWithMessage, updateOpportunityStage]
  );

  const [activeGenerateDocumentsModal, setActiveGenerateDocumentsModal] =
    useState(false);

  const [showOpportunityStatusModal, setShowOpportunityStatusModal] =
    useState(false);

  const handleCloseModal = () => {
    setActiveGenerateDocumentsModal(false);
    setShowShareTermsConfirmation(true);
    setCheckedConfirmSend(false);
    setShowOpportunityStatusModal(false);
    setProgress(0);
  };

  const [
    selectedOpportunityClosedStatus,
    setSelectedOpportunityClosedLostStatus,
  ] = useState(["won"]);

  const handleChangeOpportunityClosedStatus = useCallback(
    (value) => setSelectedOpportunityClosedLostStatus(value),
    []
  );

  const handleConfirmOpportunityStatusChange = useCallback(() => {
    if (!isOpportunityOpen) return handleOpportunityStatusUpdate("open");

    if (selectedOpportunityClosedStatus.includes("lost"))
      return handleOpportunityStatusUpdate("closed_lost");
    if (selectedOpportunityClosedStatus.includes("won"))
      return handleOpportunityStatusUpdate("closed_won");
  }, [
    handleOpportunityStatusUpdate,
    isOpportunityOpen,
    selectedOpportunityClosedStatus,
  ]);

  const opportunityStatusModal = (
    <Modal
      open={showOpportunityStatusModal}
      onClose={handleCloseModal}
      title={isOpportunityOpen ? "Close opportunity" : "Open opportunity"}
      primaryAction={{
        content: isOpportunityOpen ? "Close opportunity" : "Open opportunity",
        loading: isUpdatingOpportunity,
        onAction: handleConfirmOpportunityStatusChange,
      }}
    >
      {isOpportunityOpen ? (
        <Modal.Section>
          <FormLayout>
            <ChoiceList
              title="Was this opportunity won or lost?"
              choices={[
                { label: "Won", value: "won" },
                { label: "Lost", value: "lost" },
              ]}
              selected={selectedOpportunityClosedStatus}
              onChange={handleChangeOpportunityClosedStatus}
            />
          </FormLayout>
        </Modal.Section>
      ) : (
        <Modal.Section>
          <Text variant="bodyMd" as="p">
            Mark this opportunity as{" "}
            <Text fontWeight="bold" as="span">
              open
            </Text>
            . You will be able to continue working on this opportunity.
          </Text>
        </Modal.Section>
      )}
    </Modal>
  );

  const navigateToFinancingOptionsShare = () => {
    window.open(`/opportunities/${opportunityId}/share`, "_blank");
  };

  const [createCreditReview, { isLoading: isCreatingCreditReview }] =
    useCreateCreditReviewMutation();

  const requestCreditReviewAction = () => {
    let body = {};

    return createCreditReview({
      opportunityId: opportunityId,
      ...body,
    })
      .then((response) => {
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);

        return { status: "failure" };
      });
  };

  const [pushHubspotUpdate, { isLoading: isPushingHubspotUpdate }] =
    usePushHubspotUpdateMutation();

  const pushToHubspotAction = () => {
    return pushHubspotUpdate({
      opportunityId: opportunityId,
    })
      .then((response) => {
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);

        return { status: "failure" };
      });
  };

  const [showShareTermsConfirmation, setShowShareTermsConfirmation] =
    useState(true);
  const [checkedConfirmSend, setCheckedConfirmSend] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleConfirmSendChange = useCallback(
    (newChecked) => setCheckedConfirmSend(newChecked),
    []
  );

  const handleConfirmSendTerms = () => {
    setShowShareTermsConfirmation(false);
    generateDocs();
  };

  const handleTagOpportunities = () => {
    return updateTagOpportunities({
      opportunity_ids: [opportunityId],
      tag_ids: selectedTag.join(","),
    })
      .unwrap()
      .then(() => {
        setOpenSelectTagModal(false);
        handleSelectedTagChange([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!showShareTermsConfirmation) {
      let interval = setInterval(
        () =>
          setProgress((progress) => {
            if (progress <= 99) {
              return (progress += 1);
            } else {
              clearInterval(interval);
              return 100;
            }
          }),
        75
      );
    }

    return () => setProgress(0);
  }, [showShareTermsConfirmation]);

  const handleExpiredEmbeddedSigningToken = useCallback(async () => {
    try {
      await updateOpportunity({
        id: opportunity.id,
        embedded_signing_token_expired: true,
      }).unwrap();
      refetchOpportunity();
      return { status: "success" };
    } catch (error) {
      console.log(error);
    }
  }, [opportunity.id, refetchOpportunity, updateOpportunity]);

  useEffect(() => {
    if (docusignRedirectUrl) {
      docusignRedirectUrlExpirationCounter > 0 &&
        setTimeout(() => {
          setDocusignRedirectUrlExpirationCounter(
            docusignRedirectUrlExpirationCounter - 1000
          );
        }, 1000);

      if (
        docusignRedirectUrlExpirationCounter <= 0 &&
        !opportunity.attributes.embedded_signing_token_expired
      ) {
        handleExpiredEmbeddedSigningToken();
      }
    }

    return () => setDocusignRedirectUrlExpirationCounter(5000);
  }, [
    docusignRedirectUrl,
    docusignRedirectUrlExpirationCounter,
    handleExpiredEmbeddedSigningToken,
    opportunity.attributes.embedded_signing_token_expired,
  ]);

  const handleNavigateToDocusignRedirectUrl = useCallback(() => {
    handleExpiredEmbeddedSigningToken();
    window.location = docusignRedirectUrl;
  }, [docusignRedirectUrl, handleExpiredEmbeddedSigningToken]);

  const confirmDocumentsModalCheckboxLabel = (
    <>I am ready for the customer to receive the following document package:</>
  );
  const generateDocumentsModal = (
    <Modal
      open={activeGenerateDocumentsModal}
      onClose={handleCloseModal}
      title="Generate documents"
      primaryAction={
        showShareTermsConfirmation
          ? {
              content: "Confirm",
              onAction: handleConfirmSendTerms,
              disabled: !checkedConfirmSend,
            }
          : {
              content: "Close",
              onAction: handleCloseModal,
            }
      }
      secondaryActions={
        !showShareTermsConfirmation && [
          {
            content: "Go to in-person signing",
            onAction: handleNavigateToDocusignRedirectUrl,
            external: true,
            disabled:
              !docusignRedirectUrl ||
              !docusignRedirectUrlExpirationCounter ||
              opportunity?.attributes?.embedded_signing_token_expired,
          },
        ]
      }
    >
      <Modal.Section>
        {showGenereateDocsError && (
          <Banner title="Could not generate documents." tone="critical">
            We were unable to generate the document package you requested due to
            a system error. Please try again in a few minutes.
          </Banner>
        )}

        {showShareTermsConfirmation &&
          opportunity?.attributes?.has_accepted_financing_option &&
          opportunity?.attributes?.generate_documents_requested_at && (
            <div>
              <Banner title="Are you sure?" tone="warning">
                <p>
                  Re-generating documents will void any pre-existing document
                  package.
                </p>
                {opportunity.attributes.generate_documents_requested_at && (
                  <p>
                    Documents were last generated on{" "}
                    {formatLongDateTime(
                      new Date(
                        opportunity.attributes.generate_documents_requested_at
                      )
                    )}
                    .
                  </p>
                )}
              </Banner>
              <br></br>
            </div>
          )}

        {showShareTermsConfirmation && (
          <BlockStack gap="200">
            <Text variant="bodyMd" as="p">
              Please confirm:
            </Text>
            <Checkbox
              label={confirmDocumentsModalCheckboxLabel}
              checked={checkedConfirmSend}
              onChange={handleConfirmSendChange}
            />
            <br />
            <List type="bullet">
              {documentList.map((document_name, index) => {
                return (
                  <List.Item key={`document-${index}`}>
                    {document_name}
                  </List.Item>
                );
              })}
            </List>
          </BlockStack>
        )}

        {!showShareTermsConfirmation &&
          !docusignRedirectUrl &&
          !showGenereateDocsError && (
            <>
              <LegacyStack distribution={"center"}>
                <LegacyStack.Item fill>
                  <ProgressBar progress={progress} />
                </LegacyStack.Item>
              </LegacyStack>
              <br />
              <BlockStack>
                <LegacyStack alignment={"center"}>
                  <Text as="span" tone="subdued" style={{ minHeight: "20px" }}>
                    {progress < 100
                      ? "Creating document package... "
                      : "Sending document package to Docusign... "}
                  </Text>
                  {progress >= 100 && (
                    <DelayRender delay={200}>
                      <Spinner size={"small"} />
                    </DelayRender>
                  )}
                </LegacyStack>
              </BlockStack>
            </>
          )}

        {docusignRedirectUrl && (
          <BlockStack>
            <p>
              Financing documents have been emailed to the primary contact for
              signature. No further action required.
            </p>
            <p>
              You can also access the documents for in-person signing before the
              signing link expires.
            </p>
            <Text as="span" fontWeight="semibold">
              Time left:{" "}
              {formatMillisecondsToMinutes(
                docusignRedirectUrlExpirationCounter
              )}
            </Text>
            {docusignRedirectUrlExpirationCounter <= 0 && (
              <InlineError message={"Docusign signing link has expired."} />
            )}
          </BlockStack>
        )}
      </Modal.Section>
    </Modal>
  );

  const [showToast, setShowToast] = useState(location?.state?.showToast);
  const [customToastMessage, setCustomToastMessage] = useState("");
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    const { state: currentState } = location;
    if (currentState) {
      delete currentState.toastMessage;
      delete currentState.showToast;
    }

    window.history.replaceState({ ...currentState }, "");
    setCustomToastMessage("");
    toggleShowToast();
  };
  const toastMarkup = showToast ? (
    <Toast
      content={location?.state?.toastMessage || customToastMessage}
      onDismiss={handleDismissToast}
    />
  ) : undefined;

  const bulkDeleteOpportunities = () => {
    const opportunityIds = [opportunityId];

    return deleteOpportunities({
      opportunityIds: opportunityIds,
    })
      .unwrap()
      .then(() => {
        // Redirect to Home Index
        navigateToHome();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedTag, setSelectedTag] = useState([]);
  const [choiceListTags, setChoiceListTags] = useState([]);
  const handleSelectedTagChange = useCallback((tagVal) => {
    setSelectedTag(tagVal);
  }, []);
  const handleOpenConfirmationModal = () => {
    setOpenConfirmationModal(true);
  };
  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };
  const handleConfirmDeleteModal = () => {
    bulkDeleteOpportunities();
    setOpenConfirmationModal(false);
  };

  const areYouSureModal = (
    <Modal
      open={openConfirmationModal}
      onClose={handleCloseConfirmationModal}
      title="Are you sure?"
      primaryAction={{
        content: "Confirm",
        onAction: handleConfirmDeleteModal,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseConfirmationModal,
        },
      ]}
    >
      <Modal.Section>
        <Text variant="bodyMd" as="span">
          You are about to delete this opportunity. This action cannot be
          undone.
        </Text>
      </Modal.Section>
    </Modal>
  );

  const accountsResourceList = (
    <ResourceList
      resourceName={{
        singular: "account",
        plural: "accounts",
      }}
      items={[client]}
      renderItem={(item) => renderAccountItem(item)}
      loading={isLoadingClient}
    />
  );

  const renderAccountItem = (item) => {
    const {
      id,
      attributes: { name },
    } = item;
    const thumbnail = (
      <Thumbnail source={StoreIcon} size="extraSmall" alt={`Icon of ${name}`} />
    );
    return (
      <ResourceItem
        id={id}
        media={thumbnail}
        onClick={navigateToAccountShow}
        accessibilityLabel={`View details for ${name}`}
        verticalAlignment="center"
      >
        <LegacyStack spacing="extraLoose" alignment="center">
          <LegacyStack.Item fill>
            <Text as="span" fontWeight="semibold">
              <Truncate>{name}</Truncate>
            </Text>
          </LegacyStack.Item>
        </LegacyStack>
      </ResourceItem>
    );
  };

  function creatorMessage(opportunity) {
    return `Created by ${opportunity.attributes.creator_name}
    on
    ${formatLongDateTime(new Date(opportunity.attributes.created_at))}`;
  }

  const internalFundingAttachments = (
    <InternalFundingRequirements
      title="Attachments"
      opportunityId={opportunityId}
      opportunityAttachments={opportunityAttachments}
      refetchOpportunityAttachments={refetchOpportunityAttachments}
    />
  );

  const [notes, setNotes] = useState("");
  const [notesDirtyFlag, setNotesDirtyFlag] = useState(false);

  useEffect(() => {
    if (opportunity.attributes.notes) {
      setNotes(opportunity.attributes.notes);
    }
  }, [opportunity.attributes.notes]);

  const handleNoteChange = useCallback((newValue) => {
    setNotesDirtyFlag(true);
    setNotes(newValue);
  }, []);

  const updateNotes = () => {
    const form = { notes: notes };

    return updateOpportunity({ id: opportunity.id, ...form })
      .unwrap()
      .then(() => {
        setNotesDirtyFlag(false);
        return { status: "success" };
      })
      .catch((error) => {
        setNotesDirtyFlag(false);
        console.log(error);
      });
  };

  const toggleAccess = () => {
    setIsLoadingAccessToggle(true);
    return updateOpportunity({
      id: opportunity.id,
      public: !opportunity.attributes.public,
    })
      .unwrap()
      .then(() => {
        setCustomToastMessage("Access updated");
        toggleShowToast();

        setTimeout(() => {
          setIsLoadingAccessToggle(false);
        }, 800);
        return { status: "status" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const pendingReviewBanner = opportunity?.attributes?.human_stage_name ===
    "Approval Request" && (
    <React.Fragment>
      <Banner title="Opportunity Pending Review" tone="info">
        The FORT credit team is reviewing the available finance options. You
        will be notified with a credit decision shortly.{" "}
        <Link
          external
          url={"https://help.fortifypay.com/what-to-expect-from-credit-reviews"}
        >
          Learn more about credit decisions
        </Link>
      </Banner>
      <br />
    </React.Fragment>
  );

  const [selectedOriginator, setSelectedOriginator] = useState([]);

  useEffect(() => {
    if (opportunity?.attributes?.originator_id) {
      setSelectedOriginator([
        {
          id: opportunity.attributes.originator_id,
          attributes: {
            full_name: opportunity.attributes.originator_name,
            team_name: opportunity.attributes.originator_team_name,
          },
        },
      ]);
    }
  }, [opportunity]);

  const handleUpdateOriginator = () => {
    setCustomToastMessage("Originator updated");
    toggleShowToast();
  };

  const [showContactShareModal, setShowContactShareModal] = useState(false);

  const handleAfterShareWithContacts = () => {
    posthog?.capture("Opportunity shared");
    setCustomToastMessage("Access updated");
    toggleShowToast();
    setShowContactShareModal(false);
  };

  const handleOpportunityContactAvatarClick = () => {
    setShowContactShareModal(true);
  };

  const contactShareModal = (
    <ShareModal
      opportunityId={opportunityId}
      opportunity={opportunity}
      showContactShareModal={showContactShareModal}
      setShowContactShareModal={setShowContactShareModal}
      setCustomToastMessage={setCustomToastMessage}
      toggleShowToast={toggleShowToast}
      handleAfterShareWithContacts={handleAfterShareWithContacts}
    />
  );

  const [showOtherDetailsModal, setShowOtherDetailsModal] = useState(false);
  const [customIdValue, setCustomIdValue] = useState("");
  const [documentationFee, setDocumentationFee] = useState(
    opportunity.attributes.documentation_fee
  );
  const [installDateValue, setInstallDateValue] = useState("");
  const [updatingOtherDetails, setUpdatingOtherDetails] = useState(false);

  const updateOtherDetails = () => {
    setUpdatingOtherDetails(true);
    const form = {
      install_date: installDateValue,
      external_identifier: customIdValue,
      documentation_fee: documentationFee,
    };

    return updateOpportunity({ id: opportunity.id, ...form })
      .unwrap()
      .then(() => {
        setUpdatingOtherDetails(false);
        setShowOtherDetailsModal(false);
        return { status: "success" };
      })
      .catch((error) => {
        setUpdatingOtherDetails(false);
        console.log(error);
      });
  };

  const editOtherDetailsModal = (
    <Modal
      open={showOtherDetailsModal}
      onClose={() => setShowOtherDetailsModal(false)}
      title={"Edit Other details"}
      primaryAction={{
        content: "Save",
        loading: isLoadingOpportunity || updatingOtherDetails,
        onAction: updateOtherDetails,
      }}
    >
      <Modal.Section>
        <BlockStack>
          <TextField
            label={"Install date"}
            type="date"
            value={installDateValue}
            onChange={setInstallDateValue}
          />

          <br />

          <TextField
            label={"Custom ID"}
            type={"text"}
            autoComplete={"off"}
            value={customIdValue}
            onChange={setCustomIdValue}
          />

          {isOrganizationAdmin && (
            <>
              <br />
              <TextField
                label={"Documentation fee"}
                type={"number"}
                autoComplete={"off"}
                value={documentationFee}
                onChange={setDocumentationFee}
              />
            </>
          )}
        </BlockStack>
      </Modal.Section>
    </Modal>
  );

  const defaultLayout = (
    <Layout>
      <Layout.Section>{pendingReviewBanner}</Layout.Section>

      <Layout.Section primary>
        {/* Vendor quotes & invoices */}
        <BlockStack gap="400">
          <Card padding="0">
            <CardBoxTitle>
              <Text variant="headingMd" as="h6">
                Vendor quotes
              </Text>
            </CardBoxTitle>
            <VendorPaymentList
              opportunity={opportunity}
              opportunityId={opportunityId}
              accountId={clientId}
            />
            {isOrganizationAdmin && (
              <>
                <Divider />
                <Box
                  as="section"
                  background="bg-surface-secondary"
                  padding="400"
                >
                  <Text as="span" tone="subdued">
                    {creatorMessage(opportunity)}
                  </Text>
                </Box>
              </>
            )}
          </Card>

          {/* Deal terms */}
          <Card padding="0">
            <CardBoxTitle>
              <Text variant="headingMd" as="h6">
                Purchase options
              </Text>
            </CardBoxTitle>
            <FinancingOptionList
              canCreateFinanceOption={
                opportunity.attributes.has_vendor_payment || false
              }
              opportunityId={opportunityId}
              opportunity={opportunity}
              refetchOpportunity={refetchOpportunity}
              isOrganizationAdmin={isOrganizationAdmin}
              isVendorAdmin={isVendorAdmin}
              contacts={opportunityContacts}
            />
          </Card>
        </BlockStack>

        {/* Comments */}
        {/* <CommentList
          commentableId={opportunity.id}
          commentableType={"Opportunity"}
        /> */}
        <OpportunityCommentList
          commentableId={opportunity.id}
          commentableType={"Opportunity"}
        />
      </Layout.Section>

      <Layout.Section variant="oneThird" sectioned>
        <BlockStack gap="400">
          {/* Credit preview */}
          {(isOrganizationAdmin || isFinanceSpecialist) && (
            <CreditPreview opportunityId={opportunityId} />
          )}

          {/* Originator */}
          <Card padding="0">
            <CardBoxTitle>
              <InlineStack align="space-between">
                <Text variant="headingMd" as="h6">
                  Contacts
                </Text>
                <Button
                  variant="plain"
                  onClick={() => {
                    setShowContactShareModal(true);
                  }}
                >
                  Share
                </Button>
              </InlineStack>
            </CardBoxTitle>
            {(isOrganizationAdmin ||
              isVendorAdmin ||
              currentContactHasPermission(
                "team_admin",
                opportunity.attributes.originator_team_id
              )) && (
              <Box as="section" padding="400">
                <Text variant="headingSm" as="h6">
                  Originator
                </Text>
                <OpportunityOriginatorForm
                  opportunityId={opportunityId}
                  selectedOriginator={selectedOriginator}
                  onUpdateOriginator={handleUpdateOriginator}
                />
              </Box>
            )}

            <Divider />

            <Box as="section" padding="400">
              <Text variant="headingSm" as="h6">
                Shared with
              </Text>
              <OpportunityContactAvatarList
                opportunityId={opportunityId}
                onAvatarClick={handleOpportunityContactAvatarClick}
              />
            </Box>
          </Card>

          {/* Account profile */}
          <Card>
            <Text variant="headingMd" as="h6">
              Client
            </Text>
            {accountsResourceList}
          </Card>

          {/* Other Details */}
          <Card padding="400">
            <InlineStack gap="200" align="space-between">
              <Text variant="headingMd" as="h6">
                Other details
              </Text>

              <Button
                onClick={() => {
                  if (opportunity.attributes.install_date) {
                    setInstallDateValue(
                      new Date(
                        opportunity.attributes.install_date
                      ).toLocaleDateString("en-CA")
                    );
                  }
                  if (opportunity.attributes.external_identifier) {
                    setCustomIdValue(
                      opportunity.attributes.external_identifier
                    );
                  }
                  if (opportunity.attributes.documentation_fee) {
                    setDocumentationFee(
                      opportunity.attributes.documentation_fee
                    );
                  }
                  setShowOtherDetailsModal(true);
                }}
                variant="plain"
              >
                Edit
              </Button>
            </InlineStack>

            <BlockStack gap="200">
              <Text as="p" tone="subdued">
                Install date
              </Text>
              <Text as="p">
                {opportunity.attributes.install_date
                  ? formatNumericDate(
                      new Date(opportunity.attributes.install_date)
                    )
                  : "-"}
              </Text>
            </BlockStack>

            <br />

            <BlockStack>
              <Text as="p" tone="subdued">
                Custom ID
              </Text>
              <Text as="p">
                {opportunity.attributes.external_identifier
                  ? opportunity.attributes.external_identifier
                  : "-"}
              </Text>
            </BlockStack>

            <br />

            {isOrganizationAdmin && (
              <BlockStack>
                <Text as="p" tone="subdued">
                  Documentation fee
                </Text>
                <Text as="p">
                  {opportunity.attributes.documentation_fee_formatted
                    ? opportunity.attributes.documentation_fee_formatted
                    : "-"}
                </Text>
              </BlockStack>
            )}
          </Card>

          {/* Notes */}
          {isOrganizationAdmin && (
            <Card>
              <Box paddingBlockEnd="400">
                <Text variant="headingMd" as="h6">
                  Organization admin notes
                </Text>
                <Text variant="bodySm" as="p" tone="subdued">
                  Only organization admins can see these notes
                </Text>
              </Box>
              <TextField
                value={notes}
                onChange={handleNoteChange}
                maxHeight={"154px"}
                multiline={5}
                autoComplete="off"
              />
              {!notesDirtyFlag ? null : (
                <Box as="section" padding="300">
                  <InlineStack align="end">
                    <Button
                      variant="primary"
                      onClick={() => updateNotes()}
                      loading={isUpdatingOpportunity}
                    >
                      Save changes
                    </Button>
                  </InlineStack>
                </Box>
              )}
            </Card>
          )}

          {/* Attachments */}
          {(isFinanceSpecialist || isOrganizationAdmin) &&
            internalFundingAttachments}
        </BlockStack>
      </Layout.Section>
    </Layout>
  );

  const navigateToHome = () => {
    history.push({
      pathname: `/`,
    });
  };

  const navigateToCreditSubmissionNew = () => {
    history.push({
      pathname: `/opportunities/${opportunityId}/credit_submissions/new`,
      state: { opportunity: { ...opportunity } },
    });
  };

  const { data: creditSubmissions, isLoading: isLoadingCreditSubmissions } =
    useGetOpportunityCreditSubmissionsQuery(opportunityId);

  const creditSubmissionResourceName = {
    singular: "credit submission",
    plural: "credit submissions",
  };

  const navigateToCreditSubmission = (id) => {
    history.push({
      pathname: `/opportunities/${opportunityId}/credit_submissions/${id}`,
      state: { opportunity: { ...opportunity } },
    });
  };

  const creditSubmissionResourceList = (item) => {
    const id = item.id;
    const {
      lender_name,
      decision,
      amount_requested,
      submitted_at,
      created_at,
      updated_at,
      selected_at,
    } = item.attributes;

    const decisionBadgeStatus = () => {
      let status;
      switch (decision) {
        case "Approved":
          status = "success";
          break;
        case "Needs more info":
          status = "warning";
          break;
        case "Declined":
          status = "critical";
          break;
        default:
          status = "default";
          break;
      }
      return status;
    };

    return (
      <ResourceItem
        id={lender_name}
        onClick={() => {
          navigateToCreditSubmission(id);
        }}
        accessibilityLabel={`View details for credit submission name`}
        verticalAlignment="center"
        media={<Icon source={NoteIcon} color="subdued" />}
      >
        <InlineStack align="space-between">
          <div style={{ marginTop: "10px" }}>
            <h3>
              <Text as="span" fontWeight="semibold">
                <Truncate>
                  {lender_name} - {formatCurrency(amount_requested)}
                </Truncate>
              </Text>
            </h3>
            <Text variant="bodySm" as="p">
              <Truncate>
                Submitted{" "}
                {formatLongDateTime(new Date(submitted_at || created_at))} •
                Last updated {formatLongDateTime(new Date(updated_at))}
              </Truncate>
            </Text>
          </div>

          <div style={{ marginTop: "10px" }}>
            <Badge tone={decisionBadgeStatus()}>
              {capitalizeFirstLetter(decision)}
            </Badge>
            {selected_at && (
              <span style={{ marginLeft: "5px" }}>
                <Badge tone="success">Selected</Badge>
              </span>
            )}
          </div>
        </InlineStack>
      </ResourceItem>
    );
  };

  const newCreditSubmissionButton = (
    <Button onClick={navigateToCreditSubmissionNew}>
      New credit submission
    </Button>
  );

  const emptyCreditdSubmissionsState = (
    <EmptyState
      heading="This is where you'll manage your credit submissions"
      action={{
        content: "New credit submission",
        onAction: navigateToCreditSubmissionNew,
      }}
      image={emptySearchCompanies}
    >
      <p>You can create a new credit submission below.</p>
    </EmptyState>
  );

  const credtSubmissionLayout = (
    <Card padding="0">
      {creditSubmissions ? (
        <ResourceList
          resourceName={creditSubmissionResourceName}
          items={creditSubmissions}
          renderItem={(item) => creditSubmissionResourceList(item)}
          emptyState={emptyCreditdSubmissionsState}
          loading={isLoadingCreditSubmissions}
          showHeader
          alternateTool={newCreditSubmissionButton}
        />
      ) : (
        <ResourceList
          resourceName={creditSubmissionResourceName}
          items={[]}
          renderItem={(item) => creditSubmissionResourceList(item)}
          emptyState={emptyCreditdSubmissionsState}
          loading={true}
          showHeader
          alternateTool={newCreditSubmissionButton}
        />
      )}
    </Card>
  );

  const fundingChecklistLayout = <OpportunitiesFundingChecklist />;

  const paymentsAndReviewLayout = (
    <OpportunitiesPaymentsAndReview opportunity={opportunity} />
  );

  const embeddedSending = <OpportunitiesEmbeddedSending />;

  const opportunityTabs = (
    <OpportunityTab
      opportunityId={opportunityId}
      opportunityDetail={defaultLayout}
      creditSubmissions={credtSubmissionLayout}
      fundingChecklist={fundingChecklistLayout}
      paymentsAndReview={paymentsAndReviewLayout}
      embeddedSending={embeddedSending}
      isOrgAdmin={isOrganizationAdmin}
    />
  );

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const toggleEditingTitle = () => {
    setIsEditingTitle(!isEditingTitle);

    if (opportunity.attributes.name != pageTitle) {
      const form = { name: pageTitle };

      return updateOpportunity({ id: opportunity.id, ...form })
        .unwrap()
        .then(() => {
          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const [pageTitle, setPageTitle] = useState("");
  const [opportunityTags, setOpportunityTags] = useState([]);

  useEffect(() => {
    if (opportunity && tags) {
      const allTags =
        tags.map((tag) => {
          return { label: tag.attributes.name, value: tag.id };
        }) || [];

      setPageTitle(opportunity.attributes.name);
      setChoiceListTags(allTags);
      if (opportunity.attributes.tags?.length > 0) {
        setOpportunityTags(opportunity.attributes.tags);

        const opTags = allTags
          .filter((tag) => opportunity.attributes.tags.includes(tag.label))
          .map((tag) => tag.value);
        handleSelectedTagChange(opTags);
      } else if (selectedTag) {
        handleSelectedTagChange([]);
      }
    }
  }, [opportunity, tags]);

  const adminLayout = opportunityTabs;

  const handleRemoveTag = (tag) => () => {
    const setTags = opportunityTags.filter(
      (previousTag) => previousTag !== tag
    );
    setOpportunityTags(setTags);

    const removedTag = tags
      .filter((tg) => setTags.includes(tg.name))
      .map((tg) => tg.name);
    unTag(removedTag[0]);
  };

  const unTag = (removedTag) => {
    return updateTagOpportunities({
      opportunity_ids: [opportunityId],
      single_remove: true,
      tag_name: removedTag,
    })
      .unwrap()
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const tagMarkup = opportunityTags?.map((option) => (
    <Tag key={option} onRemove={handleRemoveTag(option)}>
      {option}
    </Tag>
  ));

  const titleComponent = (
    <InlineStack gap="200">
      {isEditingTitle ? (
        <TextField
          value={pageTitle}
          onChange={setPageTitle}
          connectedRight={
            <Button onClick={toggleEditingTitle} variant="primary">
              <Icon color={"success"} source={CheckIcon} />
            </Button>
          }
        />
      ) : (
        <LegacyStack alignment="trailing" spacing="tight" wrap={false}>
          <LegacyStack.Item>
            <span style={{ cursor: "pointer" }} onClick={toggleEditingTitle}>
              {pageTitle}
            </span>
          </LegacyStack.Item>

          {!isMobile && !isOpportunityClosedLost && (
            <div style={{ marginBottom: "-2px" }}>
              <Button
                size={"slim"}
                onClick={toggleEditingTitle}
                variant="plain"
              >
                <Icon color={"interactive"} source={EditIcon} />
              </Button>
            </div>
          )}
        </LegacyStack>
      )}
      {isOrganizationAdmin && tagMarkup}
      {isTagging ? (
        <Spinner accessibilityLabel="spinner" size="small" />
      ) : (
        <></>
      )}
    </InlineStack>
  );
  const [orderedTasks, setOrderedTasks] = useState([]);

  const orderTasks = (taskList) => {
    let partialList = [];
    let incompleteList = [];
    let completeList = [];

    for (let i = 0; i < taskList.length; i++) {
      if (taskList[i].status === "complete") {
        completeList.push(taskList[i]);
      } else if (taskList[i].status === "partial") {
        partialList.push(taskList[i]);
      } else {
        incompleteList.push(taskList[i]);
      }
    }

    setOrderedTasks([...completeList, ...partialList, ...incompleteList]);
  };

  useEffect(() => {
    if (opportunity && opportunity.attributes?.requirement_status) {
      orderTasks(opportunity.attributes?.requirement_status);
    }
  }, [opportunity]);

  const subtitleComponent = (
    <>
      <Box minWidth="120px" maxWidth="240px">
        <TaskListProgressBar
          completedTasksCount={opportunity.attributes.completed_tasks_count}
          totalTasksCount={opportunity.attributes.total_tasks_count}
          opportunityId={opportunityId}
        />
      </Box>
    </>
  );

  const titleMetadataComponent = (
    <>
      {isOpportunityClosedLost && <Badge tone="critical">Closed - Lost</Badge>}
      {isOpportunityClosedWon && <Badge tone="success">Closed - Won</Badge>}
      {isOpportunityOpen && <Badge tone="info">Open</Badge>}
      {isOpportunityTbd && <Badge tone="warning">TBD</Badge>}
    </>
  );

  const tagAction = {
    content:
      opportunity?.attributes?.tags?.length > 0 ? "Update tags" : "Add tag",
    onAction: () => setOpenSelectTagModal(true),
    loading: isTagging,
  };

  const viewClientPortalAction = {
    content: "View client portal",
    onAction: navigateToFinancingOptionsShare,
    external: true,
  };

  const submitForCreditApprovalAction = {
    content: "Submit for credit review",
    onAction: requestCreditReviewAction,
    loading: isCreatingCreditReview,
  };

  const pushToHubspotSecondaryAction = {
    content: "Push to Hubspot",
    onAction: pushToHubspotAction,
  };

  const openOpportunitySecondaryAction = {
    content: "Open opportunity",
    onAction: () => setShowOpportunityStatusModal(true),
  };

  const closeOpportunitySecondaryAction = {
    content: "Close opportunity",
    onAction: () => setShowOpportunityStatusModal(true),
  };

  const deleteOpportunitySecondaryAction = {
    content: "Delete opportunity",
    destructive: true,
    outline: true,
    onAction: handleOpenConfirmationModal,
  };

  function compileSecondaryActions() {
    const actions = [tagAction, viewClientPortalAction];

    if (isOrganizationAdmin && opportunity.attributes.hubspot_deal_id) {
      actions.push(
        {
          icon: ViewIcon,
          content: "View on Hubspot",
          external: true,
          url: `https://app.hubspot.com/contacts/${process.env.HUBSPOT_DEAL_CONTACT_ID}/deal/${opportunity.attributes.hubspot_deal_id}`,
        },
        submitForCreditApprovalAction,
        pushToHubspotSecondaryAction
      );
    } else {
      actions.push(submitForCreditApprovalAction);
    }

    if (isOpportunityClosedLost || isOpportunityClosedWon) {
      actions.push(openOpportunitySecondaryAction);
    } else {
      actions.push(closeOpportunitySecondaryAction);
    }

    actions.push(deleteOpportunitySecondaryAction);

    return actions.filter((action) => {
      if (!isOrganizationAdmin) {
        return !["Add tag", "Update tags"].includes(action.content);
      }
      return true;
    });
  }

  const secondaryActions = compileSecondaryActions();

  const pageMarkup =
    opportunity && !isLoadingOpportunity ? (
      opportunityError && [403, 404].includes(opportunityError.status) ? (
        <FourZeroFour />
      ) : (
        <div className="FortPage__Title--editable">
          <Page
            title={titleComponent}
            titleMetadata={titleMetadataComponent}
            subtitle={subtitleComponent}
            secondaryActions={secondaryActions}
            fullWidth
          >
            <Confetti active={isOpportunityFunded} />

            {isOrganizationAdmin || isVendorAdmin ? adminLayout : defaultLayout}

            {contactShareModal}
            {editOtherDetailsModal}
            {generateDocumentsModal}
            {opportunityStatusModal}
            {areYouSureModal}
            {toastMarkup}
            <AddTagModalComponent
              tags={opportunity?.attributes?.tags}
              isTagging={isTagging}
              selectedTag={selectedTag}
              choiceListTags={choiceListTags}
              openSelectTagModal={openSelectTagModal}
              setOpenSelectTagModal={setOpenSelectTagModal}
              handleTagOpportunities={handleTagOpportunities}
              handleSelectedTagChange={handleSelectedTagChange}
            />
          </Page>
        </div>
      )
    ) : (
      <OpportunityShowSkeletonPage opportunity={opportunity} withPage={true} />
    );

  return currentContact ? pageMarkup : <LoadingScreen />;
};

export default OpportunitiesShow;
