import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  OptionList
} from "@shopify/polaris";

const AddTagModalComponent = (props) => {
  const {
    tags,
    isTagging,
    selectedTag,
    choiceListTags,
    openSelectTagModal,
    setOpenSelectTagModal,
    handleTagOpportunities,
    handleSelectedTagChange,
  } = props;

  return (
    <Modal
      open={openSelectTagModal}
      onClose={() => setOpenSelectTagModal(false)}
      title={tags?.length > 0 ? "Update tags" : "Add tag"}
      loading={isTagging}
      primaryAction={{
        content: tags?.length > 0 ? "Update tags" : "Add tag",
        onAction: handleTagOpportunities,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => setOpenSelectTagModal(false),
        },
      ]}
    >
      <Modal.Section>
        {choiceListTags && (
          <OptionList
            title="Available tags"
            onChange={handleSelectedTagChange}
            options={choiceListTags}
            selected={selectedTag}
            allowMultiple
          />
        )}
      </Modal.Section>
    </Modal>
  );
};

AddTagModalComponent.propTypes = {
  tags: PropTypes.array,
  choiceListTags: PropTypes.array,
  isTagging: PropTypes.bool,
  selectedTag: PropTypes.any,
  openSelectTagModal: PropTypes.bool,
  setOpenSelectTagModal: PropTypes.func,
  handleSelectedTagChange: PropTypes.func,
  handleTagOpportunities: PropTypes.func,
};

export default AddTagModalComponent;
