import React from "react";

import { Select, FormLayout, Box } from "@shopify/polaris";

type TimingFieldProps = {
  fields: any;
  period: string;
  paymentType: string;
  handlePaymentTypeSelectChange: (selected: string, id: string) => void;
};

const TimingFields = (props: TimingFieldProps) => {
  const {
    fields,
    period = "monthly",
    paymentType = "arrears",
    handlePaymentTypeSelectChange,
  } = props;

  const paymentTypeOptions = [
    { label: "Advance", value: "advance" },
    { label: "Arrears", value: "arrears" },
  ];
  const periodOptions = [{ label: "Monthly", value: "monthly" }];

  return (
    <FormLayout>
      <FormLayout.Group>
        <Select
          label="Period"
          options={periodOptions}
          {...fields.period}
        />
        <Select
          label="Payment Type"
          options={paymentTypeOptions}
          {...fields.payment_type}
        />
      </FormLayout.Group>
    </FormLayout>
  );
};

export default TimingFields;
